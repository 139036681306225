import React from 'react';

import PropTypes from 'prop-types';
import { rgbToHex } from 'utils';

import ExportTableBody from './export-table-body';
import ExportTableFooter from './export-table-footer';
import ExportTableHeader from './export-table-header';
import ExportTableInfo from './export-table-info';

const ExportTable = ({ columns, data, info, companyLogo, title }) => {
    const documentStyles = getComputedStyle(document.documentElement);

    const pdfTextColor = documentStyles.getPropertyValue('--pdf-text').replaceAll(' ', ', ');
    const pdfLayoutTextColor = documentStyles.getPropertyValue('--pdf-layout-text').replaceAll(' ', ', ');
    const pdfLayoutBackgroundColor = rgbToHex(
        ...documentStyles.getPropertyValue('--base-pdf-layout-background').split(' '),
    );
    const disabledColor = rgbToHex(...documentStyles.getPropertyValue('--base-disabled').split(' '));

    const rowLength = columns.length;

    return (
        <table>
            <ExportTableInfo
                companyLogo={companyLogo}
                rowLength={rowLength}
                textColor={pdfLayoutTextColor}
                backgroundColor={pdfLayoutBackgroundColor}
            >
                {title}
            </ExportTableInfo>
            <ExportTableHeader textColor={pdfTextColor} backgroundColor={disabledColor} columns={columns} />
            <ExportTableBody data={data} columns={columns} />
            {info && <ExportTableFooter textColor={pdfTextColor} info={info} rowLength={rowLength} />}
        </table>
    );
};

ExportTable.propTypes = {
    columns: PropTypes.array,
    data: PropTypes.array,
    info: PropTypes.object,
    companyLogo: PropTypes.string,
    title: PropTypes.string,
};

ExportTable.defaultProps = {
    columns: [],
    data: [],
    info: null,
    companyLogo: null,
    title: null,
};

export default ExportTable;
