import { makeStyles } from '@material-ui/core';

const DEFAULT_INVOICE = {
    name: '',
    isValidated: true,
    billingNumber: '',
    issueDate: null,
    type: null,
    incomeType: null,
    projectStructureActivityId: null,
    contractId: null,
    contractPMId: null,
    partnerId: null,
    expenses: [],
    expenseNames: [],
    revenues: [],
    invoiceIds: [],
    invoiceArticles: [],
    crmProjectId: null,
    pmProjectOverviewPlanId: null,
    value: 0,
    secondValue: 0,
    manualExchange: false,
    exchangeDate: null,
    exchangeRate: 1,
    VAT: 0,
    valueWithVat: 0,
    secondValueWithVat: 0,
};

const DEFAULT_REVENUES_AND_EXPENSES = {
    grandParentId: null,
    list: [],
};

const getStyles = makeStyles(() => {
    return {
        expense: {
            backgroundColor: `#05668D`,
            color: 'var(--buttons-text)',
            '&:hover': {
                opacity: 0.8,
                backgroundColor: `#05668D`,
            },
        },
        income: {
            backgroundColor: `#00A896`,
            color: 'var(--buttons-text)',
            '&:hover': {
                opacity: 0.8,
                backgroundColor: `#00A896`,
            },
        },
        advance: {
            backgroundColor: `#9B5094`,
            color: 'var(--buttons-text)',
            '&:hover': {
                opacity: 0.8,
                backgroundColor: `#9B5094`,
            },
        },
        reverse: {
            backgroundColor: `#F5A65B`,
            color: 'var(--buttons-text)',
            '&:hover': {
                opacity: 0.8,
                backgroundColor: `#F5A65B`,
            },
        },
        error: {
            backgroundColor: `var(--error)`,
            color: `var(--buttons-text)`,
            '&:hover': {
                backgroundColor: `var(--error-light)`,
            },
        },
        success: {
            backgroundColor: `var(--success)`,
            color: `var(--buttons-text)`,
            '&:hover': {
                backgroundColor: `var(--success-light)`,
            },
        },
    };
});

const handleChangeTitle = (invoice) => {
    let title = '';

    switch (invoice.type) {
        case 'EXPENSE':
            title = 'Configure the expense invoice';
            break;
        case 'ADVANCE':
            title = 'Configure the advance invoice';
            break;
        case 'REVERSAL':
            title = 'Configure the reversal invoice';
            break;
        case 'INCOME':
            if (invoice.incomeType === 'SIMPLE') {
                title = 'Configure the simple income invoice';
            } else if (invoice.incomeType === 'REVERSE') {
                title = 'Configure the income invoice with advance reversal';
            }
            break;
    }

    return title;
};

const canRenderClientSection = (invoice, selectedAdvanceInvoices) => {
    let render = false;

    switch (invoice.type) {
        case 'INCOME':
            switch (invoice.incomeType) {
                case 'SIMPLE':
                    if (invoice.value !== 0) render = true;
                    break;
                case 'REVERSE':
                    if (selectedAdvanceInvoices.length > 0) render = true;
                    break;
                default:
                    render = false;
            }
            break;
        case 'EXPENSE':
            if (invoice.value !== 0) render = true;
            break;
        case 'ADVANCE':
            if (invoice.value !== 0) render = true;
            break;
        default:
            render = false;
    }

    return render;
};

const canRenderContractAndProjectSection = (invoice, invoiceClient, selectedPartner) =>
    (invoiceClient !== null || selectedPartner !== null) && (invoice.type !== 'EXPENSE' || invoice.type !== 'REVERSAL');

const canRenderArticlesAndAccountancy = (
    invoice,
    selectedContract,
    selectedCrmProject,
    invoiceOnAContract,
    selectedProject,
    invoiceOnAProject,
) => {
    let render = false;

    switch (invoice.type) {
        case 'INCOME':
            if (
                ((selectedContract !== null || selectedCrmProject !== null) && invoiceOnAContract) ||
                (invoiceOnAProject && selectedProject !== null)
            )
                render = true;
            break;
        case 'EXPENSE':
            if (
                ((selectedContract !== null || selectedCrmProject !== null) && invoiceOnAContract) ||
                (selectedProject !== null && invoiceOnAProject)
            )
                render = true;
            break;
        case 'ADVANCE':
            if (
                ((selectedContract !== null || selectedCrmProject !== null) && invoiceOnAContract) ||
                (invoiceOnAProject && selectedProject !== null)
            )
                render = true;
            break;
        case 'REVERSAL':
            if (invoice.value !== 0) render = true;
            break;
        default:
            render = false;
    }

    return render;
};

const canRenderLastFields = (
    invoice,
    checkedExpenses,
    validExpenseValue,
    checkedRevenues,
    validRevenueValue,
    selectedAdvanceInvoices,
    advanceInvoices,
) => {
    let render = false;

    switch (invoice.type) {
        case 'INCOME':
            switch (invoice.incomeType) {
                case 'SIMPLE':
                    if (checkedRevenues.list.length > 0 && validRevenueValue) render = true;
                    break;
                case 'REVERSE':
                    if (
                        checkedRevenues.list.length > 0 &&
                        validRevenueValue &&
                        selectedAdvanceInvoices.length > 0 &&
                        !selectedAdvanceInvoices.map((i) => advanceInvoices[i].value !== 0).includes(false)
                    )
                        render = true;
                    break;
                default:
                    render = false;
            }
            break;
        case 'EXPENSE':
            if (checkedExpenses.list.length > 0 && validExpenseValue) render = true;
            break;
        case 'ADVANCE':
            if (checkedRevenues.list.length > 0 && validRevenueValue) render = true;
            break;
        case 'REVERSAL':
            if (
                selectedAdvanceInvoices.length > 0 &&
                !selectedAdvanceInvoices.map((i) => advanceInvoices[i].value !== 0).includes(false) &&
                checkedRevenues.list.length > 0 &&
                validRevenueValue
            )
                render = true;
            break;
        default:
            render = false;
    }

    return render;
};

const canRenderSubmitButton = (invoice) =>
    invoice.name !== '' && invoice.billingNumber !== '' && invoice.issueDate !== null;

const createInvoiceBody = ({
    invoice,
    clients,
    partners,
    contracts,
    crmProjects,
    projects,
    invoiceClient,
    selectedPartner,
    selectedContract,
    selectedCrmProject,
    selectedProject,
    selectedArticleIds,
    selectedAdvanceInvoices,
    advanceInvoices,
    apiExchangeRate,
    invoiceOnAContract,
    invoiceOnAProject,
    isBudgetCase,
    checkedExpenses,
    checkedRevenues,
}) => {
    let body = {
        name: invoice.name,
        isValidated: true,
        billingNumber: invoice.billingNumber,
        issueDate: invoice.issueDate,
        type: invoice.type,
        value: Number(invoice.value),
        valueWithVat: Number(invoice.valueWithVat),
        secondValue: Number(invoice.secondValue),
        secondValueWithVat: Number(invoice.secondValueWithVat),
        exchangeDate: invoice.exchangeDate,
        exchangeRate: invoice.exchangeRate,
        manualExchange: invoice.exchangeRate !== apiExchangeRate,
        VAT: invoice.VAT,
        articles: [...selectedArticleIds.nomenclature, ...selectedArticleIds.stocks],
    };

    switch (invoice.type) {
        case 'EXPENSE':
            body.contactId = clients[invoiceClient]?.id ?? null;
            body.partnerId = partners[selectedPartner]?.id ?? null;

            if (isBudgetCase) {
                body.expenses = checkedExpenses.list;
                body.expenseNames = [];
            } else {
                body.expenses = [];
                body.expenseNames = checkedExpenses.list;
            }

            if (invoiceOnAContract) {
                body.contractId = contracts[selectedContract]?.id ?? null;
                body.crmProjectId = crmProjects[selectedCrmProject]?.id ?? null;
                body.contractPMId = null;
                body.pmProjectOverviewPlanId = null;
            }

            if (invoiceOnAProject) {
                body.contractId = null;
                body.crmProjectId = null;
                body.contractPMId = contracts[selectedContract]?.id ?? null;
                body.pmProjectOverviewPlanId = projects[selectedProject]?.id ?? null;
            }

            break;
        case 'ADVANCE':
            body.contactId = clients[invoiceClient]?.id ?? null;
            body.partnerId = partners[selectedPartner]?.id ?? null;
            body.revenues = checkedRevenues.list;

            if (invoiceOnAContract) {
                body.contractId = contracts[selectedContract]?.id ?? null;
                body.crmProjectId = crmProjects[selectedCrmProject]?.id ?? null;
                body.contractPMId = null;
                body.pmProjectOverviewPlanId = null;
            }

            if (invoiceOnAProject) {
                body.contractId = null;
                body.crmProjectId = null;
                body.contractPMId = contracts[selectedContract]?.id ?? null;
                body.pmProjectOverviewPlanId = projects[selectedProject]?.id ?? null;
            }

            break;
        case 'REVERSAL':
            body.invoiceIds = selectedAdvanceInvoices.map((i) => ({
                id: advanceInvoices[i]?.id,
            }));
            body.revenues = checkedRevenues.list;

            break;
        case 'INCOME':
            if (invoice.incomeType === 'SIMPLE') {
                body.contactId = clients[invoiceClient]?.id ?? null;
                body.partnerId = partners[selectedPartner]?.id ?? null;
                body.incomeType = 'SIMPLE';
                body.revenues = checkedRevenues.list;

                if (invoiceOnAContract) {
                    body.contractId = contracts[selectedContract]?.id ?? null;
                    body.crmProjectId = crmProjects[selectedCrmProject]?.id ?? null;
                    body.contractPMId = null;
                    body.pmProjectOverviewPlanId = null;
                }

                if (invoiceOnAProject) {
                    body.contractId = null;
                    body.crmProjectId = null;
                    body.contractPMId = contracts[selectedContract]?.id ?? null;
                    body.pmProjectOverviewPlanId = projects[selectedProject]?.id ?? null;
                }
            } else if (invoice.incomeType === 'REVERSE') {
                body.contactId = clients[invoiceClient]?.id ?? null;
                body.partnerId = partners[selectedPartner]?.id ?? null;

                body.incomeType = 'REVERSE';
                body.invoiceIds = selectedAdvanceInvoices.map((i) => ({
                    id: advanceInvoices[i]?.id,
                }));
                body.revenues = checkedRevenues.list;

                if (invoiceOnAContract) {
                    body.contractId = contracts[selectedContract]?.id ?? null;
                    body.crmProjectId = crmProjects[selectedCrmProject]?.id ?? null;
                    body.contractPMId = null;
                    body.pmProjectOverviewPlanId = null;
                }

                if (invoiceOnAProject) {
                    body.contractId = null;
                    body.crmProjectId = null;
                    body.contractPMId = contracts[selectedContract]?.id ?? null;
                    body.pmProjectOverviewPlanId = projects[selectedProject]?.id ?? null;
                }
            }
            break;
    }

    return body;
};

export {
    DEFAULT_INVOICE,
    DEFAULT_REVENUES_AND_EXPENSES,
    getStyles,
    handleChangeTitle,
    canRenderClientSection,
    canRenderContractAndProjectSection,
    canRenderArticlesAndAccountancy,
    canRenderLastFields,
    canRenderSubmitButton,
    createInvoiceBody,
};
