import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import DeleteIcon from '@material-ui/icons/Delete';

import { Button, Checkbox, CircularProgress, FormControlLabel, TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';

import FileUploadContainer from 'components/shared/file-upload-container';
import PDFPreview from 'components/shared/pdf-preview/pdf-preview';
import ValueDisplay from 'components/shared/value-display';
import GlobalContext from 'contexts/GlobalContext';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { ActionButton, DatePicker, Dropdown, LabelWrapper, MultiDropdown } from 'RaisisComponents/index.js';
import { LocaleTextField } from 'RaisisComponents/Inputs';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { accountancy } from 'routes';
import API from 'utils/axios';
import {
    getContacts,
    getPartners,
    getStocksArticles,
    getCompanyVAT as handleGetCompanyVAT,
} from 'utils/getterFunctions';
import {
    calculateSumWithVAT,
    errorHandling,
    formatExchangeRate,
    formatPositiveNumberWithDigits,
    formatVATnumber,
    toLocaleNumber,
    toRoundedSignificantDigits,
    uploadSingleFile,
} from 'utils/index';
import {
    canRenderArticlesAndAccountancy,
    canRenderClientSection,
    canRenderContractAndProjectSection,
    canRenderLastFields,
    canRenderSubmitButton,
    createInvoiceBody,
    DEFAULT_INVOICE,
    DEFAULT_REVENUES_AND_EXPENSES,
    getStyles,
    handleChangeTitle,
} from 'utils/invoicesUtils';
import * as yup from 'yup';

import InvoiceClientDropdown from './invoice-client-dropdown';
import InvoiceExpenseBudget from './invoice-expense-budget';
import InvoicePartnerDropdown from './invoice-partner-dropdown';
import InvoiceRevenueBudget from './invoice-revenue-budget';
import InvoiceReversal from './invoice-reversal';
import InvoiceTypeSelection from './invoice-type-selection';
import InvoiceValues from './invoice-values';

const InvoiceForm = (props) => {
    const styles = getStyles();

    const { invoiceToEdit, viewOnly, displayCrmProjects, displayPmProjects } = props;

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const { invoiceId } = useParams();
    const { currencyObj, referenceCurrencyObj, language } = useContext(GlobalContext);

    const [invoice, setInvoice] = useState({ ...DEFAULT_INVOICE });

    const [clients, setClients] = useState([]);
    const [clientOptions, setClientOptions] = useState([]);
    const [invoiceClient, setInvoiceClient] = useState(null);

    const [selectedPartner, setSelectedPartner] = useState(null);
    const [partners, setPartners] = useState([]);
    const [partnersOptions, setPartnersOptions] = useState([]);

    // States for PDF preview
    const [invoicePDF, setInvoicePDF] = useState(null);
    const [pageNumberPDF, setPageNumberPDF] = useState(1);

    // States for the CRM contracts
    const [invoiceOnAContract, setInvoiceOnAContract] = useState(false);

    // CRM & PM Contracts
    const [contractsOptions, setContractsOptions] = useState([]);
    const [contracts, setContracts] = useState([]);
    const [crmProjects, setCrmProjects] = useState([]);
    const [selectedContract, setSelectedContract] = useState(null);
    const [selectedCrmProject, setSelectedCrmProject] = useState(null);

    // States for the Projects
    const [invoiceOnAProject, setInvoiceOnAProject] = useState(false);
    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);

    // States for the reverse invoice
    const [advanceInvoices, setAdvanceInvoices] = useState([]);
    const [selectedAdvanceInvoices, setSelectedAdvanceInvoices] = useState([]);

    // States for the articles
    const [articlesFromNomenclature, setArticlesFromNomenclature] = useState([]);
    const [articlesFromStocks, setArticlesFromStocks] = useState([]);
    const [selectedArticleIds, setSelectedArticleIds] = useState({ nomenclature: [], stocks: [] });

    const handlePDFUpload = useCallback(async (e) => {
        await uploadSingleFile(
            e,
            ({ message, blob }) => {
                if (message) {
                    enqueueSnackbar(message, { variant: 'error' });
                    return;
                }

                setInvoicePDF(blob);
            },
            'file',
        );
    }, []);

    /**
     * Function for editing the invoice info
     * @param {String} key - the key in the object to be modified
     * @param {String} value - the new value for the key
     */
    const editInvoiceInfo = (key, value) => {
        setInvoice((prev) => ({ ...prev, [key]: value }));
    };

    /**
     *
     * @param {*} resetAllInvoice - can be all to reset 'all' invoices and the pdfTemplates, and
     * if it is empty, will reset all invoice Info, but no pdf
     */
    const handleResetInvoiceInfo = (resetAllInvoice) => {
        setInvoice(DEFAULT_INVOICE);
        setInvoiceClient(null);
        setSelectedPartner(null);

        setSelectedContract(null);
        setSelectedProject(null);

        setInvoiceOnAContract(false);
        setInvoiceOnAProject(false);

        setSelectedAdvanceInvoices([]);

        setCheckedExpenses(DEFAULT_REVENUES_AND_EXPENSES);
        setCheckedRevenues(DEFAULT_REVENUES_AND_EXPENSES);

        if (resetAllInvoice === 'all') {
            setInvoicePDF(null);
            setPageNumberPDF(1);
        }

        getCompanyVAT();
        getReferenceExchangeRate(new Date(), true);
    };

    const handleResetMiddleFields = () => {
        setSelectedContract(null);
        setSelectedProject(null);
        setInvoiceOnAContract(false);
        setInvoiceOnAProject(false);
        setCheckedExpenses(DEFAULT_REVENUES_AND_EXPENSES);
        setCheckedRevenues(DEFAULT_REVENUES_AND_EXPENSES);
    };

    // ------------------ EXCHANGE RATES AND VAT LOGIC --------------------------------
    const [apiExchangeRate, setApiExchangeRate] = useState(0);
    const [loadingExchangeRate, setLoadingExchangeRate] = useState(false);
    const [loadingVAT, setLoadingVAT] = useState(false);

    const disableInputs = loadingExchangeRate || loadingVAT;

    const handleUpdateExchangeRate = (e) => {
        const value = formatExchangeRate(e.target.value);
        const secondValue = invoice.value / value;
        const secondValueWithVat = calculateSumWithVAT(secondValue, invoice.VAT);

        setCheckedRevenues((prev) => ({
            ...prev,
            list: prev.list.map((r) => ({ ...r, secondCurrencyValue: r.value / value })),
        }));
        setCheckedExpenses((prev) => ({
            ...prev,
            list: prev.list.map((r) => ({ ...r, secondCurrencyValue: r.value / value })),
        }));

        editInvoiceInfo('secondValue', secondValue);
        editInvoiceInfo('secondValueWithVat', secondValueWithVat);
        editInvoiceInfo('exchangeRate', value);
    };

    const handleUpdateVAT = (e) => {
        const value = formatVATnumber(e.target.value);
        const invoiceValueWithVat = calculateSumWithVAT(invoice.value, value);
        const invoiceSecondValueWithVat = calculateSumWithVAT(invoice.value / invoice.exchangeRate, value);

        editInvoiceInfo('VAT', value);
        editInvoiceInfo('valueWithVat', invoiceValueWithVat);
        editInvoiceInfo('secondValueWithVat', invoiceSecondValueWithVat);
    };

    const getExchangeRate = async (e) => {
        try {
            let selectedDate = new Date(e);
            if (selectedDate > new Date()) {
                enqueueSnackbar(t("The selected date can't be greater then the current date!"), {
                    variant: 'error',
                });
                selectedDate = new Date();
            }

            editInvoiceInfo('exchangeDate', selectedDate);
            setLoadingExchangeRate(true);

            const res = await API.get('currencyByDate', {
                params: {
                    date: selectedDate,
                },
            });

            const { rates } = res.data.data;

            handleUpdateExchangeRate({ target: { value: +rates } });
            setApiExchangeRate(+rates);
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingExchangeRate(false);
        }
    };

    const getReferenceExchangeRate = async (e, canSetInitial = false) => {
        try {
            setLoadingExchangeRate(true);

            const res = await API.get('currencyByDate', {
                params: {
                    date: new Date(e),
                },
            });

            const { rates } = res.data.data;

            if (canSetInitial) {
                editInvoiceInfo('exchangeDate', e);
                editInvoiceInfo('exchangeRate', +rates);
            }
            setApiExchangeRate(+rates);
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingExchangeRate(false);
        }
    };

    const getCompanyVAT = async () => {
        try {
            setLoadingVAT(true);
            const resVat = await handleGetCompanyVAT();
            editInvoiceInfo('VAT', resVat);
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingVAT(false);
        }
    };

    /**
     * Logic for updating the invoice value
     */
    const handleUpdateInvoiceValue = (e) => {
        const value = formatPositiveNumberWithDigits(e.target.value);
        const secondValue = value / invoice.exchangeRate;
        const invoiceValueWithVat = calculateSumWithVAT(value, invoice.VAT);
        const invoiceSecondValueWithVat = calculateSumWithVAT(value / invoice.exchangeRate, invoice.VAT);

        editInvoiceInfo('value', value);
        editInvoiceInfo('secondValue', secondValue);
        editInvoiceInfo('valueWithVat', invoiceValueWithVat);
        editInvoiceInfo('secondValueWithVat', invoiceSecondValueWithVat);
    };

    const getAllClients = async () => {
        try {
            const res = await getContacts();

            setClients(res);
            setClientOptions(
                res.map((c) => {
                    const data = JSON.parse(c.data);
                    return 'standard' in data && 'name' in data['standard'] ? data['standard'].name : 'Nume inexistent';
                }),
            );

            return res;
        } catch (err) {
            console.error(err);
        }
    };

    const getAllPartners = async () => {
        try {
            const partnersAccounts = await getPartners();

            setPartners(partnersAccounts);
            setPartnersOptions(
                partnersAccounts.map((partner) => {
                    const data = typeof partner.data === 'string' ? JSON.parse(partner.data) : partner.data;
                    return data['standard'].name;
                }),
            );

            return partnersAccounts;
        } catch (err) {
            console.error(err);
            enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                variant: 'error',
            });
        }
    };

    const getAllArticles = async () => {
        try {
            const [articlesRes, articlesFromStocks] = await Promise.all([
                await API.get('/articles', {
                    params: {
                        perPage: 99999,
                        currentPage: 0,
                        pagesToLoad: 1,
                        type: null,
                        categorieId: null,
                        many: 'ALL',
                    },
                }),
                getStocksArticles(),
            ]);

            setArticlesFromNomenclature(articlesRes.data.articles);
            setArticlesFromStocks(articlesFromStocks);

            return { nomenclatureArticles: articlesRes.data.articles, stocksArticles: articlesFromStocks };
        } catch (err) {
            console.error(err);
            enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                variant: 'error',
            });
            throw err;
        }
    };

    useEffect(() => {
        if (invoiceToEdit) return;

        const getInitialData = async () => {
            try {
                await Promise.all([
                    getCompanyVAT(),
                    getReferenceExchangeRate(new Date(), true),
                    getAllClients(),
                    getAllPartners(),
                    getAllArticles(),
                    getAdvanceInvoices(),
                ]);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        getInitialData();
    }, []);

    const getNewContracts = async (client, partner) => {
        let newDisplayedContracts = [];

        if (client !== null) {
            const res = await API.get('/contactsContracts', { params: { contactId: client } });
            newDisplayedContracts = res.data.contactsContracts.contracts;
        } else if (client === null && partner !== null) {
            const res = await API.get('/contactsContracts', { params: { partnerId: partner } });
            newDisplayedContracts = res.data.contactsContracts.contracts;
        } else {
            newDisplayedContracts = [];
        }

        setContracts(newDisplayedContracts);

        if (newDisplayedContracts.length !== 0) {
            const contractsOptions = newDisplayedContracts.map((c) => {
                const data = typeof c.data === 'string' ? JSON.parse(c.data) : c.data;
                return 'standard' in data && 'number' in data['standard']
                    ? data['standard'].number
                    : 'Numar contract inexistent';
            });

            setContractsOptions(contractsOptions);
        } else {
            setContractsOptions([]);
        }

        return newDisplayedContracts;
    };

    const getNewCrmProjects = async (clientId) => {
        try {
            if (!clientId) {
                setCrmProjects([]);
                return [];
            }

            const rowProjects = await API.get('/client_crmProjects', {
                params: {
                    id: clientId,
                },
            });

            setCrmProjects(rowProjects.data.contactsCrmProjects.projects);

            return rowProjects.data.contactsCrmProjects.projects;
        } catch (error) {
            console.error(error);
        }
    };

    const getNewCrmData = async (clientId, partnerId) => {
        try {
            await getNewContracts(clientId, partnerId);
            await getNewCrmProjects(clientId);
        } catch (error) {
            console.error(error);
        }
    };

    const getNewProjects = async (client, partner) => {
        let newDisplayedProjects = [];

        if (client !== null) {
            const res = await API.get('/contactsPm_projects', {
                params: { perPage: 99999, currentPage: 0, pagesToLoad: 1, contactId: client },
            });
            newDisplayedProjects = res.data.projects;
        } else if (client === null && partner !== null) {
            const res = await API.get('/partnersPm_projects', {
                params: { perPage: 99999, currentPage: 0, pagesToLoad: 1, partnerId: partner },
            });
            newDisplayedProjects = res.data.projects;
        } else {
            newDisplayedProjects = [];
        }

        setProjects(newDisplayedProjects);

        return newDisplayedProjects;
    };

    const getPMContracts = async (pmId) => {
        try {
            const response = await API.get('project_contracts_pm', {
                params: {
                    currentPage: 0,
                    perPage: 99999,
                    pagesToLoad: 1,
                    projectId: pmId,
                },
            });

            const resContracts = response.data.contracts;
            setContracts(resContracts);

            const contractsOptions = resContracts.map((c) => {
                const data = typeof c.data === 'string' ? JSON.parse(c.data) : c.data;
                return 'standard' in data && 'number' in data['standard']
                    ? data['standard'].number
                    : 'Numar contract inexistent';
            });
            setContractsOptions(contractsOptions);

            return resContracts;
        } catch (err) {
            console.error(err);
            enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                variant: 'error',
            });
        }
    };

    const getAdvanceInvoices = async () => {
        try {
            const res = await API.get('/invoices', {
                params: {
                    type: 'ADVANCE',
                    perPage: 99999,
                    currentPage: 0,
                    pagesToLoad: 1,
                    isUsed: invoiceToEdit ? undefined : false,
                },
            });

            const newInvoices = res.data.invoices.filter(
                (invoice) => invoice.isValidated === true && invoice.payment === 'PAID',
            );

            setAdvanceInvoices(newInvoices);

            return newInvoices;
        } catch (err) {
            console.error(err);
            enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                variant: 'error',
            });
        }
    };

    /**
     * With this function we reset al the info from an income invoice when the user change the income invoice type
     */
    const handleIncomeTypeChangeReset = () => {
        editInvoiceInfo('incomeType', null);

        setInvoiceClient(null);
        setSelectedPartner(null);

        setSelectedContract(null);
        setSelectedProject(null);
        setSelectedCrmProject(null);

        setInvoiceOnAContract(false);
        setInvoiceOnAProject(false);

        setSelectedAdvanceInvoices([]);

        getCompanyVAT();
        getReferenceExchangeRate(new Date(), true);
    };

    const invoiceInfoSchema = yup.object().shape({
        invoiceName: yup
            .string()
            .trim()
            .typeError(t('The invoice name is required'))
            .required(t('The invoice name is required')),
        invoiceNumber: yup
            .string()
            .trim()
            .typeError(t('The invoice number is required'))
            .required(t('The invoice number is required'))
            .min(3, t('The invoice number must be at least 3 characters long!')),
    });

    const handleInvoiceCreationOrUpdate = async () => {
        try {
            await invoiceInfoSchema.validate({
                invoiceName: invoice.name,
                invoiceNumber: invoice.billingNumber,
            });

            try {
                setLoading(true);

                const data = createInvoiceBody({
                    invoice,
                    clients,
                    partners,
                    contracts,
                    crmProjects,
                    projects,
                    invoiceClient,
                    selectedPartner,
                    selectedContract,
                    selectedCrmProject,
                    selectedProject,
                    selectedArticleIds,
                    selectedAdvanceInvoices,
                    advanceInvoices,
                    apiExchangeRate,
                    invoiceOnAContract,
                    invoiceOnAProject,
                    isBudgetCase,
                    checkedExpenses,
                    checkedRevenues,
                });

                if (invoiceId) {
                    await API.put('/invoice', {
                        id: invoiceId,
                        data,
                    });

                    enqueueSnackbar(t('The invoice has been updated successfully!'), {
                        variant: 'success',
                    });
                } else {
                    const reqBody = new FormData();

                    reqBody.append('data', JSON.stringify(data));
                    reqBody.append('files', invoicePDF);

                    await API.post('/invoice', reqBody, { 'Content-Type': 'multipart/form-data' });

                    enqueueSnackbar(t('The invoice has been added successfully!'), {
                        variant: 'success',
                    });

                    if (
                        invoice.type === 'REVERSAL' ||
                        (invoice.type === 'INCOME' && invoice.incomeType === 'REVERSE')
                    ) {
                        await Promise.all(
                            data.invoiceIds.map(
                                async (invoice) =>
                                    await API.put('/invoice', {
                                        id: invoice.id,
                                        data: {
                                            isUsed: true,
                                        },
                                    }),
                            ),
                        );
                    }
                }

                history.push(accountancy.base + accountancy.invoice.base);
            } catch (err) {
                console.error(err);
                enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                    variant: 'error',
                });
            } finally {
                setLoading(false);
            }
        } catch (err) {
            console.error(err);
            enqueueSnackbar(err.errors[0], {
                variant: 'error',
            });
        }
    };

    /**
     * This function transform invoiceToEdit object in an object like DEFAULT_INVOICE
     */
    const transformInvoiceToEdit = async () => {
        try {
            const newInvoice = {
                ...DEFAULT_INVOICE,
                name: invoiceToEdit.name,
                isValidated: invoiceToEdit.isValidated,
                billingNumber: invoiceToEdit.billingNumber,
                issueDate: invoiceToEdit.issueDate,
                type: invoiceToEdit.type,
                incomeType: invoiceToEdit.incomeType,
                projectStructureActivityId: invoiceToEdit.projectStructureActivityId,
                contractId: invoiceToEdit.contractId,
                contractPMId: invoiceToEdit.contractPMId,
                partnerId: invoiceToEdit.partnerId,
                crmProjectId: invoiceToEdit.crmProjectId,
                pmProjectOverviewPlanId: invoiceToEdit.pmProjectOverviewPlanId,
                value: invoiceToEdit.value,
                secondValue: invoiceToEdit.secondValue,
                manualExchange: invoiceToEdit.manualExchange,
                exchangeDate: invoiceToEdit.exchangeDate,
                exchangeRate: invoiceToEdit.exchangeRate,
                VAT: invoiceToEdit.VAT,
                valueWithVat: invoiceToEdit.valueWithVat,
                secondValueWithVat: invoiceToEdit.secondValueWithVat,
            };

            const contractPromise = { promise: null, args: [] };
            const projectPromise = { promise: null, args: [] };

            if (invoiceToEdit.contractId !== null || invoiceToEdit.crmProjectId !== null) {
                contractPromise.promise = getNewContracts;
                contractPromise.args = [invoiceToEdit.contactId, invoiceToEdit.partnerId];

                projectPromise.promise = getNewCrmProjects;
                projectPromise.args = [invoiceToEdit.contactId];
            }

            if (invoiceToEdit.contractPMId !== null) {
                contractPromise.promise = getPMContracts;
                contractPromise.args = [invoiceToEdit.pmProjectOverviewPlanId];
            }

            if (invoiceToEdit.pmProjectOverviewPlanId !== null) {
                projectPromise.promise = getNewProjects;
                projectPromise.args = [invoiceToEdit.contactId, invoiceToEdit.partnerId];
            }

            const [
                returnedClients,
                returnedPartners,
                returnedAdvanceInvoices,
                returnedContracts = [],
                returnedProjects,
                returnedArticles,
            ] = await Promise.all([
                getAllClients(),
                getAllPartners(),
                getAdvanceInvoices(),
                contractPromise.promise ? contractPromise.promise(...contractPromise.args) : undefined,
                projectPromise.promise ? projectPromise.promise(...projectPromise.args) : undefined,
                getAllArticles(),
                getReferenceExchangeRate(invoiceToEdit.exchangeDate),
            ]);

            setInvoicePDF({
                httpHeaders: {
                    'Access-Control-Allow-Origin': '*',
                },
                url: invoiceToEdit.fileArray[0]?.signedUrl,
            });

            // We set the invoice client dropdown with the selected invoice client
            if (invoiceToEdit.contactId !== null) {
                const clientIndex = returnedClients.findIndex((c) => c.id === invoiceToEdit.contactId);
                setInvoiceClient(clientIndex);
            }

            if (invoiceToEdit.partnerId !== null) {
                const partnerIndex = returnedPartners.findIndex((p) => p.id === invoiceToEdit.partnerId);
                setSelectedPartner(partnerIndex);
            }

            if (invoiceToEdit.pmProjectOverviewPlanId !== null) {
                setInvoiceOnAProject(true);
                setSelectedProject(returnedProjects.findIndex((p) => p.id === invoiceToEdit.pmProjectOverviewPlanId));
                if (invoiceToEdit.contractPMId)
                    setSelectedContract(returnedContracts.findIndex((c) => c.id === invoiceToEdit.contractPMId));
            } else {
                setInvoiceOnAContract(true);
                if (invoiceToEdit.contractId)
                    setSelectedContract(returnedContracts.findIndex((c) => c.id === invoiceToEdit.contractId));

                if (invoiceToEdit.crmProjectId)
                    setSelectedCrmProject(returnedProjects.findIndex((p) => p.id === invoiceToEdit.crmProjectId));
            }

            // For advance invoices dropdown
            if (invoiceToEdit.invoiceIds.length) {
                const selectedAdvanceInvoicesIndexes = invoiceToEdit.invoiceIds.reduce((acc, curr) => {
                    const invoiceIdx = returnedAdvanceInvoices.findIndex((invoice) => invoice.id === curr.id);
                    if (invoiceIdx >= 0) return [...acc, invoiceIdx];

                    return acc;
                }, []);

                setSelectedAdvanceInvoices(selectedAdvanceInvoicesIndexes);
            }

            if (invoiceToEdit.InvoiceArticles.length) {
                const selectedArticleIds = invoiceToEdit.InvoiceArticles.reduce(
                    (acc, curr) => {
                        const nomenclatureArticleId = returnedArticles.nomenclatureArticles.find(
                            (article) => article.id === curr.articleId,
                        )?.id;

                        if (nomenclatureArticleId)
                            return { ...acc, nomenclature: [...acc.nomenclature, nomenclatureArticleId] };

                        const stocksArticleId = returnedArticles.stocksArticles.find(
                            (article) => article.id === curr.articleId,
                        )?.id;

                        if (stocksArticleId) return { ...acc, stocks: [...acc.stocks, stocksArticleId] };

                        return acc;
                    },
                    { nomenclature: [], stocks: [] },
                );

                setSelectedArticleIds(selectedArticleIds);
            }

            setInvoice(newInvoice);

            if (invoiceToEdit.type === 'EXPENSE') {
                let checkedExpenses = {};

                const isBudgetaryCase =
                    invoiceToEdit.InvoiceToExpense.length > 0
                        ? true
                        : invoiceToEdit.InvoiceToExpenseName.length > 0
                          ? false
                          : false;

                setIsBudgetCase(isBudgetaryCase);

                if (isBudgetaryCase) {
                    const grandParentId =
                        invoiceToEdit.InvoiceToExpense[0].expense.expenseName.subExpenseName.subExpenseName === null
                            ? invoiceToEdit.InvoiceToExpense[0].expense.expenseName.subExpenseName.id
                            : invoiceToEdit.InvoiceToExpense[0].expense.expenseName.subExpenseName.subExpenseName.id;

                    checkedExpenses = {
                        grandParentId,
                        list: invoiceToEdit.InvoiceToExpense.map((item) => ({
                            id: item.expenseId,
                            value: item.value,
                            secondCurrencyValue: item.secondCurrencyValue,
                        })),
                    };
                } else {
                    const grandParentId =
                        invoiceToEdit.InvoiceToExpenseName[0].expenseName.subExpenseName.subExpenseName === null
                            ? invoiceToEdit.InvoiceToExpenseName[0].expenseName.subExpenseName.id
                            : invoiceToEdit.InvoiceToExpenseName[0].expenseName.subExpenseName.subExpenseName.id;

                    checkedExpenses = {
                        grandParentId,
                        list: invoiceToEdit.InvoiceToExpenseName.map((expense) => ({
                            id: expense.expenseNameId,
                            value: expense.value,
                            secondCurrencyValue: expense.secondCurrencyValue,
                        })),
                    };
                }

                setCheckedExpenses(checkedExpenses);
            }

            if (
                invoiceToEdit.type === 'INCOME' ||
                invoiceToEdit.type === 'REVERSAL' ||
                invoiceToEdit.type === 'ADVANCE'
            ) {
                const grandParentId =
                    invoiceToEdit.InvoiceToRevenue[0].revenue.subRevenue.subRevenue === null
                        ? invoiceToEdit.InvoiceToRevenue[0].revenue.subRevenue.id
                        : invoiceToEdit.InvoiceToRevenue[0].revenue.subRevenue.subRevenue.id;

                const checkedRevenues = {
                    grandParentId,
                    list: invoiceToEdit.InvoiceToRevenue.map((revenue) => ({
                        id: revenue.revenueId,
                        value: revenue.value,
                        secondCurrencyValue: revenue.secondCurrencyValue,
                    })),
                };

                setCheckedRevenues(checkedRevenues);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!invoiceToEdit) return;

        transformInvoiceToEdit();
    }, [invoiceToEdit]);

    // ------------------------------ Logic for the expenses ----------------------------------

    const [checkedExpenses, setCheckedExpenses] = useState(DEFAULT_REVENUES_AND_EXPENSES);
    const [isBudgetCase, setIsBudgetCase] = useState(false);

    const checkedExpensesSum = toRoundedSignificantDigits(
        checkedExpenses.list.reduce((sum, current) => (sum += current.value), 0),
    );
    const validExpenseValue = checkedExpensesSum === invoice.value;

    // ------------------------------ Logic for the revenue ----------------------------------
    const [checkedRevenues, setCheckedRevenues] = useState(DEFAULT_REVENUES_AND_EXPENSES);

    const checkedRevenuesSum = toRoundedSignificantDigits(
        checkedRevenues.list.reduce((sum, current) => (sum += current.value), 0),
    );
    const validRevenueValue = checkedRevenuesSum === invoice.value;

    // ------------------------------ Render logic ----------------------------------
    const renderClientSection = canRenderClientSection(invoice, selectedAdvanceInvoices);
    const renderContractAndProjectSection = canRenderContractAndProjectSection(invoice, invoiceClient, selectedPartner);
    const renderArticlesAndAccountancy = canRenderArticlesAndAccountancy(
        invoice,
        selectedContract,
        selectedCrmProject,
        invoiceOnAContract,
        selectedProject,
        invoiceOnAProject,
    );
    const renderLastFields =
        renderArticlesAndAccountancy &&
        canRenderLastFields(
            invoice,
            checkedExpenses,
            validExpenseValue,
            checkedRevenues,
            validRevenueValue,
            selectedAdvanceInvoices,
            advanceInvoices,
        );
    const renderSubmitButton = renderLastFields && canRenderSubmitButton(invoice);

    return (
        <>
            {loading ? (
                <div className="flex h-64 w-full items-center justify-center bg-layout-main">
                    <CircularProgress />
                </div>
            ) : (
                <div className="flex gap-24 lg:flex-col lg:items-center">
                    {/**
                     * First section with PDF preview and invoice type
                     *  */}

                    <div className="flex flex-col items-center">
                        {/* Button for pdf upload - this will disappear after the upload of the invoice PDF */}
                        {!invoicePDF && (
                            <FileUploadContainer onUpload={(e) => handlePDFUpload(e)}>
                                <Button color="secondary" startIcon={<i className="fa-solid fa-file-invoice" />}>
                                    {t('Upload invoice PDF')}
                                </Button>
                            </FileUploadContainer>
                        )}

                        {/* PDF preview */}
                        <PDFPreview file={invoicePDF} pageNumber={pageNumberPDF} setPageNumber={setPageNumberPDF} />
                        <InvoiceTypeSelection
                            editInvoiceInfo={editInvoiceInfo}
                            onResetInvoiceInfo={handleResetInvoiceInfo}
                            invoicePDF={invoicePDF}
                            invoiceId={invoiceId}
                            viewOnly={viewOnly}
                            type={invoice.type}
                        />
                    </div>

                    {/* Invoice configuration */}
                    <div className={`${viewOnly ? 'pointer-events-none' : ''}`}>
                        {/* Invoice configurator title */}
                        {invoice.type !== null && (
                            <h4 className="mb-20 text-left text-2xl text-main-text">{t(handleChangeTitle(invoice))}</h4>
                        )}
                        {/* Income type invoice */}
                        {/* Selecting area for the income invoice type */}
                        {invoice.type === 'INCOME' && !invoice.incomeType && (
                            <div className="mb-10 flex flex-wrap justify-start gap-5">
                                <Button
                                    className={styles.income}
                                    startIcon={<i className="fa-solid fa-file-invoice" />}
                                    onClick={() => editInvoiceInfo('incomeType', 'SIMPLE')}
                                >
                                    {t('Simple income invoice')}
                                </Button>

                                <Button
                                    className={styles.income}
                                    startIcon={<i className="fa-solid fa-file-invoice" />}
                                    onClick={() => editInvoiceInfo('incomeType', 'REVERSE')}
                                >
                                    {t('Income invoice with advance reversal')}
                                </Button>
                            </div>
                        )}
                        {/* Button for changing the income type */}
                        {invoice.type === 'INCOME' && invoice.incomeType && !viewOnly && (
                            <div className="mb-20 flex justify-start">
                                <Button
                                    color="primary"
                                    startIcon={<i className="fa-solid fa-file-invoice" />}
                                    onClick={handleIncomeTypeChangeReset}
                                >
                                    {t('Change the income invoice type')}
                                </Button>
                            </div>
                        )}
                        <InvoiceValues
                            invoice={invoice}
                            disableInputs={disableInputs}
                            getExchangeRate={getExchangeRate}
                            onUpdateVAT={handleUpdateVAT}
                            onUpdateExchangeRate={handleUpdateExchangeRate}
                            onUpdateInvoiceValue={handleUpdateInvoiceValue}
                        />

                        {/* 
                            Reversal type invoice - displayed only on reverse invoice  
                        */}
                        {invoice.type === 'INCOME' && invoice.incomeType === 'REVERSE' && invoice.value > 0 && (
                            <InvoiceReversal
                                invoice={invoice}
                                advanceInvoices={advanceInvoices}
                                selectedAdvanceInvoices={selectedAdvanceInvoices}
                                setSelectedAdvanceInvoices={setSelectedAdvanceInvoices}
                                handleUpdateInvoiceValue={handleUpdateInvoiceValue}
                            />
                        )}
                        {renderClientSection && (
                            <div className="mb-6 flex flex-col gap-4">
                                <div className="flex items-center gap-3">
                                    <InvoiceClientDropdown
                                        clients={clients}
                                        clientOptions={clientOptions}
                                        invoiceClient={invoiceClient}
                                        setInvoiceClient={setInvoiceClient}
                                        onResetMiddleFields={handleResetMiddleFields}
                                        getNewCrmData={getNewCrmData}
                                        getNewProjects={getNewProjects}
                                        selectedPartnerId={partners[selectedPartner]?.id ?? null}
                                        disabled={invoice.type === 'ADVANCE' ? selectedPartner !== null : false}
                                    />

                                    <div className="mt-6">
                                        <ActionButton
                                            icon={<DeleteIcon />}
                                            color={invoiceClient !== null ? 'var(--error)' : 'var(--disabled)'}
                                            disabled={invoiceClient !== null ? false : true}
                                            onClick={() => {
                                                setInvoiceClient(null);
                                                getNewCrmData(null, partners[selectedPartner]?.id ?? null);
                                                getNewProjects(null, partners[selectedPartner]?.id ?? null);
                                                handleResetMiddleFields();
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="flex items-center gap-3">
                                    <InvoicePartnerDropdown
                                        partnersOptions={partnersOptions}
                                        selectedPartner={selectedPartner}
                                        setSelectedPartner={setSelectedPartner}
                                        onResetMiddleFields={handleResetMiddleFields}
                                        partners={partners}
                                        selectedClientId={clients[invoiceClient]?.id ?? null}
                                        getNewCrmData={getNewCrmData}
                                        getNewProjects={getNewProjects}
                                        disabled={invoice.type === 'ADVANCE' ? invoiceClient !== null : false}
                                    />

                                    <div className="mt-6">
                                        <ActionButton
                                            icon={<DeleteIcon />}
                                            color={selectedPartner !== null ? 'var(--error)' : 'var(--disabled)'}
                                            disabled={selectedPartner !== null ? false : true}
                                            onClick={() => {
                                                setSelectedPartner(null);
                                                getNewCrmData(clients[invoiceClient]?.id ?? null, null);
                                                getNewProjects(clients[invoiceClient]?.id ?? null, null);
                                                handleResetMiddleFields();
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        {/* Section with contracts and projects for the invoice */}
                        {renderContractAndProjectSection && (
                            <div className="mb-10 flex flex-row justify-start gap-5 lg:flex-col">
                                {displayCrmProjects && (
                                    <div className="flex flex-col gap-4">
                                        {/* Checkbox for the contracts */}
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={invoiceOnAContract}
                                                    onChange={() => {
                                                        if (invoiceOnAProject && invoiceOnAContract === false) {
                                                            enqueueSnackbar(
                                                                t(
                                                                    'The invoice can be based on a contract or on a project, but not both!',
                                                                ),
                                                                {
                                                                    variant: 'error',
                                                                },
                                                            );
                                                            return;
                                                        }

                                                        if (invoiceOnAContract === true) {
                                                            setSelectedContract(null);
                                                            setSelectedProject(null);
                                                            setCheckedExpenses(DEFAULT_REVENUES_AND_EXPENSES);
                                                            setCheckedRevenues(DEFAULT_REVENUES_AND_EXPENSES);
                                                            getNewProjects(
                                                                clients[invoiceClient]?.id ?? null,
                                                                partners[selectedPartner]?.id ?? null,
                                                            );
                                                        }

                                                        setInvoiceOnAContract(!invoiceOnAContract);
                                                    }}
                                                    name="CRM contracts"
                                                    style={{
                                                        color: 'var(--primary-main)',
                                                    }}
                                                    color="primary"
                                                />
                                            }
                                            label={
                                                <p className="user-select-none">
                                                    {t('Invoice based on a CRM contract or project')}
                                                </p>
                                            }
                                        />

                                        {/* Dropdown for CRM contracts */}
                                        {invoiceOnAContract ? (
                                            contractsOptions.length > 0 ? (
                                                <LabelWrapper label={t('CRM contracts')}>
                                                    <div className="flex max-w-min items-center gap-3">
                                                        <Dropdown
                                                            disabled={selectedCrmProject !== null}
                                                            variant="black"
                                                            options={contractsOptions}
                                                            placeholder={t('Choose the contract for the invoice')}
                                                            selectedOption={selectedContract}
                                                            setSelectedOption={(e) => {
                                                                setSelectedContract(e);
                                                            }}
                                                        />

                                                        <ActionButton
                                                            icon={<DeleteIcon />}
                                                            color={
                                                                selectedContract !== null
                                                                    ? 'var(--error)'
                                                                    : 'var(--disabled)'
                                                            }
                                                            disabled={selectedContract !== null ? false : true}
                                                            onClick={() => setSelectedContract(null)}
                                                        />
                                                    </div>
                                                </LabelWrapper>
                                            ) : (
                                                <div className="max-w-sm flex-wrap text-center font-semibold italic text-error">
                                                    {t(
                                                        'The selected client has no contract yet! Create a new one or pick another client for the invoice!',
                                                    )}
                                                </div>
                                            )
                                        ) : null}

                                        {invoiceOnAContract && invoiceClient ? (
                                            crmProjects.length > 0 ? (
                                                <LabelWrapper label={t('CRM projects')}>
                                                    <div className="flex max-w-min items-center gap-3">
                                                        <Dropdown
                                                            disabled={selectedContract !== null}
                                                            variant="black"
                                                            options={crmProjects.map((p) => p.name)}
                                                            placeholder={t('Choose the project for the invoice')}
                                                            selectedOption={selectedCrmProject}
                                                            setSelectedOption={(e) => {
                                                                setSelectedCrmProject(e);
                                                            }}
                                                        />

                                                        <ActionButton
                                                            icon={<DeleteIcon />}
                                                            color={
                                                                selectedCrmProject !== null
                                                                    ? 'var(--error)'
                                                                    : 'var(--disabled)'
                                                            }
                                                            disabled={selectedCrmProject !== null ? false : true}
                                                            onClick={() => setSelectedCrmProject(null)}
                                                        />
                                                    </div>
                                                </LabelWrapper>
                                            ) : (
                                                <div className="max-w-sm flex-wrap text-center font-semibold italic text-error">
                                                    {t(
                                                        'The selected client has no project yet! Create a new one or pick another client for the invoice!',
                                                    )}
                                                </div>
                                            )
                                        ) : null}
                                    </div>
                                )}

                                {displayPmProjects && (
                                    <div className="flex flex-col gap-4">
                                        <div>
                                            {/* Checkbox for projects */}
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={invoiceOnAProject}
                                                        onChange={() => {
                                                            if (invoiceOnAContract && invoiceOnAProject === false) {
                                                                enqueueSnackbar(
                                                                    t(
                                                                        'The invoice can be based on a contract or on a project, but not both!',
                                                                    ),
                                                                    {
                                                                        variant: 'error',
                                                                    },
                                                                );
                                                                return;
                                                            }
                                                            if (invoiceOnAProject === true) {
                                                                setSelectedContract(null);
                                                                setSelectedProject(null);
                                                                setCheckedExpenses(DEFAULT_REVENUES_AND_EXPENSES);
                                                                setCheckedRevenues(DEFAULT_REVENUES_AND_EXPENSES);
                                                                getNewCrmData(
                                                                    clients[invoiceClient]?.id ?? null,
                                                                    partners[selectedPartner]?.id ?? null,
                                                                );
                                                            }
                                                            setInvoiceOnAProject(!invoiceOnAProject);
                                                        }}
                                                        name="CRM contracts"
                                                        style={{
                                                            color: 'primary-main',
                                                        }}
                                                        color="primary"
                                                    />
                                                }
                                                label={
                                                    <p className="user-select-none">
                                                        {t('Invoice based on a PM project')}
                                                    </p>
                                                }
                                            />
                                        </div>

                                        {/* Dropdown for invoice project */}
                                        {invoiceOnAProject &&
                                            (projects.map((project) => project.name).length > 0 ? (
                                                <div className="inline-block max-w-min">
                                                    <LabelWrapper label={t('Projects')}>
                                                        <Dropdown
                                                            variant="black"
                                                            options={projects.map((project) => project.name)}
                                                            placeholder={t('Choose the project for the invoice')}
                                                            selectedOption={selectedProject}
                                                            setSelectedOption={(e) => {
                                                                setSelectedProject(e);
                                                                getPMContracts(projects[e].id);
                                                            }}
                                                        />
                                                    </LabelWrapper>
                                                </div>
                                            ) : (
                                                <div className="max-w-sm flex-wrap text-center font-semibold italic text-error">
                                                    {t(
                                                        'The selected client or partner has no project yet! Create a new one or pick another client for the invoice!',
                                                    )}
                                                </div>
                                            ))}

                                        {selectedProject !== null &&
                                            (contractsOptions.length > 0 ? (
                                                <div className="flex items-center gap-3">
                                                    <div className="inline-block max-w-min">
                                                        <LabelWrapper label={t('PM contracts')}>
                                                            <Dropdown
                                                                variant="black"
                                                                options={contractsOptions}
                                                                placeholder={t('Choose the contract for the invoice')}
                                                                selectedOption={selectedContract}
                                                                setSelectedOption={(e) => {
                                                                    setSelectedContract(e);
                                                                }}
                                                            />
                                                        </LabelWrapper>
                                                    </div>

                                                    <div className="mt-5 flex-shrink-0">
                                                        <ActionButton
                                                            icon={<DeleteIcon />}
                                                            color={
                                                                selectedContract !== null
                                                                    ? 'var(--error)'
                                                                    : 'var(--disabled)'
                                                            }
                                                            disabled={selectedContract !== null ? false : true}
                                                            onClick={() => {
                                                                setSelectedContract(null);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            ) : !viewOnly ? (
                                                <div className="max-w-sm flex-wrap text-center font-semibold italic text-error">
                                                    {t(
                                                        'The selected client or partner has no contract yet! If you want the invoice to be on a contract you have to create one first!',
                                                    )}
                                                </div>
                                            ) : null)}
                                    </div>
                                )}
                            </div>
                        )}
                        {invoice.type === 'REVERSAL' && (
                            <div className="mb-10">
                                <InvoiceReversal
                                    invoice={invoice}
                                    advanceInvoices={advanceInvoices}
                                    selectedAdvanceInvoices={selectedAdvanceInvoices}
                                    setSelectedAdvanceInvoices={setSelectedAdvanceInvoices}
                                    handleUpdateInvoiceValue={handleUpdateInvoiceValue}
                                />
                            </div>
                        )}
                        {renderArticlesAndAccountancy && (
                            <Fragment>
                                <div className="mb-10">
                                    <p className="mb-2 mt-2 text-xl font-bold">{t('Select articles')}</p>
                                    <div className="flex items-center gap-5">
                                        <LabelWrapper label={t('Add article from nomenclature')}>
                                            <MultiDropdown
                                                options={articlesFromNomenclature.map((a) => a.name)}
                                                selectedOptions={articlesFromNomenclature.reduce((acc, cur, index) => {
                                                    if (selectedArticleIds.nomenclature.includes(cur.id))
                                                        return [...acc, index];

                                                    return acc;
                                                }, [])}
                                                setSelectedOptions={(i) => {
                                                    const id = articlesFromNomenclature[i].id;
                                                    setSelectedArticleIds((prev) => ({
                                                        ...prev,
                                                        nomenclature: prev.nomenclature.includes(id)
                                                            ? prev.nomenclature.filter(
                                                                  (identifier) => identifier !== id,
                                                              )
                                                            : [...prev.nomenclature, id],
                                                    }));
                                                }}
                                            />
                                        </LabelWrapper>
                                        <LabelWrapper label={t('Add article from stocks')}>
                                            <MultiDropdown
                                                options={articlesFromStocks.map((a) => `${a.name} - (${a.Stock.name})`)}
                                                selectedOptions={articlesFromStocks.reduce((acc, cur, index) => {
                                                    if (selectedArticleIds.stocks.includes(cur.id))
                                                        return [...acc, index];

                                                    return acc;
                                                }, [])}
                                                setSelectedOptions={(i) => {
                                                    const id = articlesFromStocks[i].id;
                                                    setSelectedArticleIds((prev) => ({
                                                        ...prev,
                                                        stocks: prev.stocks.includes(id)
                                                            ? prev.stocks.filter((identifier) => identifier !== id)
                                                            : [...prev.stocks, id],
                                                    }));
                                                }}
                                            />
                                        </LabelWrapper>
                                    </div>
                                </div>

                                {invoice.type === 'EXPENSE' && (
                                    <Fragment>
                                        <InvoiceExpenseBudget
                                            checkedExpenses={checkedExpenses}
                                            setCheckedExpenses={setCheckedExpenses}
                                            isEdit={invoiceToEdit ? true : false}
                                            contractData={{
                                                id: contracts[selectedContract]?.id ?? null,
                                                canFetchContract: invoiceOnAProject
                                                    ? false
                                                    : invoiceOnAContract && selectedContract !== null
                                                      ? true
                                                      : false,
                                            }}
                                            viewOnly={viewOnly}
                                            isBudgetCase={isBudgetCase}
                                            setIsBudgetCase={setIsBudgetCase}
                                            exchangeRate={invoice.exchangeRate}
                                            disableInputs={disableInputs}
                                        />
                                        {checkedExpenses.list.length > 0 && (
                                            <h4
                                                className={`mb-10 max-w-2xl ${
                                                    validExpenseValue ? 'text-success' : 'text-error'
                                                }`}
                                            >
                                                {`${t('The value of the invoice')}: ${toLocaleNumber(invoice.value, language, 2, 2)} ${
                                                    currencyObj.currency
                                                } ${t(
                                                    'must be equal to the sum of values of the checked expenses',
                                                )}: ${toLocaleNumber(checkedExpensesSum, language, 2, 2)} ${currencyObj.currency}.`}
                                            </h4>
                                        )}
                                    </Fragment>
                                )}

                                {invoice.type !== 'EXPENSE' && (
                                    <Fragment>
                                        <InvoiceRevenueBudget
                                            checkedRevenues={checkedRevenues}
                                            setCheckedRevenues={setCheckedRevenues}
                                            isEdit={invoiceToEdit ? true : false}
                                            viewOnly={viewOnly}
                                            exchangeRate={invoice.exchangeRate}
                                            disableInputs={disableInputs}
                                        />
                                        {checkedRevenues.list.length > 0 && (
                                            <h4
                                                className={`mb-10 max-w-2xl ${
                                                    validRevenueValue ? 'text-success' : 'text-error'
                                                }`}
                                            >
                                                {`${t('The value of the invoice')}: ${toLocaleNumber(invoice.value, language, 2, 2)} ${
                                                    currencyObj.currency
                                                } ${t(
                                                    'must be equal to the sum of values of the checked revenues',
                                                )}: ${toLocaleNumber(checkedRevenuesSum, language, 2, 2)} ${currencyObj.currency}.`}
                                            </h4>
                                        )}
                                    </Fragment>
                                )}
                            </Fragment>
                        )}
                        {/* 
                    Reversal type invoice - displayed only on reverse invoice  
                */}
                        {/* The final steps with the info of the invoice */}
                        {renderLastFields && (
                            <div className="mb-28">
                                <div className="mb-10 flex max-w-4xl gap-10 sm:flex-col">
                                    <LabelWrapper label={t('Invoice name')}>
                                        <TextField
                                            placeholder={t('Invoice name')}
                                            value={invoice.name}
                                            onChange={(e) => editInvoiceInfo('name', e.target.value)}
                                        />
                                    </LabelWrapper>

                                    <LabelWrapper label={t('Invoice number')}>
                                        <TextField
                                            placeholder={'F1234'}
                                            value={invoice.billingNumber}
                                            onChange={(e) => editInvoiceInfo('billingNumber', e.target.value)}
                                        />
                                    </LabelWrapper>
                                </div>

                                <div className="flex justify-start">
                                    <div className="inline-block">
                                        <LabelWrapper label={t('Issue date of the invoice')}>
                                            <DatePicker
                                                date={invoice.issueDate}
                                                setDate={(e) => editInvoiceInfo('issueDate', e)}
                                            />
                                        </LabelWrapper>
                                    </div>
                                </div>
                            </div>
                        )}
                        {/* Button for the add of the invoice and button for the reset of the invoice*/}
                        {renderSubmitButton && !viewOnly && (
                            <div className="mt-10 flex justify-start gap-5">
                                <Button
                                    className={styles.success}
                                    startIcon={<i className="fa-solid fa-file-invoice" />}
                                    onClick={handleInvoiceCreationOrUpdate}
                                    disabled={disableInputs}
                                >
                                    {invoiceId ? t('Update the invoice') : t('Add the invoice')}
                                </Button>

                                {!invoiceId && (
                                    <Button
                                        className={styles.error}
                                        startIcon={<i className="fa-solid fa-file-invoice" />}
                                        onClick={() => handleResetInvoiceInfo('all')}
                                    >
                                        {t('Reset the invoice')}
                                    </Button>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default InvoiceForm;

InvoiceForm.propTypes = {
    invoiceToEdit: PropTypes.object,
    viewOnly: PropTypes.bool,
    displayCrmProjects: PropTypes.bool,
    displayPmProjects: PropTypes.bool,
};

InvoiceForm.defaultProps = {
    invoiceToEdit: null,
    viewOnly: false,
    displayCrmProjects: true,
    displayPmProjects: true,
};
