import React from 'react';

import PropTypes from 'prop-types';

import ExportTableCell from './export-table-cell';

const ExportTableHeader = ({ columns, textColor, backgroundColor }) => {
    return (
        <tr className="bg-disabled">
            {columns.map((column) => (
                <ExportTableCell
                    key={column.key}
                    column={column}
                    textColor={textColor}
                    backgroundColor={backgroundColor}
                >
                    {column.content}
                </ExportTableCell>
            ))}
        </tr>
    );
};

ExportTableHeader.propTypes = {
    columns: PropTypes.array,
    backgroundColor: PropTypes.string,
    textColor: PropTypes.string,
};

ExportTableHeader.defaultProps = {
    columns: [],
    backgroundColor: null,
    textColor: null,
};

export default ExportTableHeader;
