import React from 'react';

import { Button } from '@material-ui/core';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getStyles } from 'utils/invoicesUtils';

const InvoiceTypeSelection = ({ editInvoiceInfo, onResetInvoiceInfo, invoicePDF, invoiceId, viewOnly, type }) => {
    const { t } = useTranslation();
    const styles = getStyles();

    return (
        <>
            {/* Buttons to select the invoice type */}
            {invoicePDF && type === null && (
                <div className="align-center mb-10 flex flex-col justify-center">
                    <h4 className="mb-10 text-center text-main-text">{t('Pick the invoice type')}</h4>
                    <div className="m-auto flex flex-wrap justify-start gap-5">
                        <Button
                            className={styles.expense}
                            startIcon={<i className="fa-solid fa-file-invoice" />}
                            onClick={() => editInvoiceInfo('type', 'EXPENSE')}
                        >
                            {t('Expense')}
                        </Button>

                        <Button
                            className={styles.income}
                            startIcon={<i className="fa-solid fa-file-invoice" />}
                            onClick={() => editInvoiceInfo('type', 'INCOME')}
                        >
                            {t('Income')}
                        </Button>

                        <Button
                            className={styles.advance}
                            startIcon={<i className="fa-solid fa-file-invoice" />}
                            onClick={() => editInvoiceInfo('type', 'ADVANCE')}
                        >
                            {t('Advance')}
                        </Button>

                        <Button
                            className={styles.reverse}
                            startIcon={<i className="fa-solid fa-file-invoice" />}
                            onClick={() => editInvoiceInfo('type', 'REVERSAL')}
                        >
                            {t('Reverse')}
                        </Button>
                    </div>
                </div>
            )}
            {/* This button will change the invoice type to null and will reset the invoice info */}
            {invoicePDF && type !== null && !invoiceId && !viewOnly && (
                <div className="flex justify-center">
                    <Button
                        color="primary"
                        startIcon={<i className="fa-solid fa-file-invoice" />}
                        onClick={() => {
                            editInvoiceInfo('type', null);
                            onResetInvoiceInfo();
                        }}
                    >
                        {t('Change the invoice type')}
                    </Button>
                </div>
            )}
        </>
    );
};

InvoiceTypeSelection.propTypes = {
    editInvoiceInfo: PropTypes.func,
    onResetInvoiceInfo: PropTypes.func,
    invoicePDF: PropTypes.object,
    invoiceId: PropTypes.string,
    viewOnly: PropTypes.bool,
    type: PropTypes.string,
};

InvoiceTypeSelection.defaultProps = {
    editInvoiceInfo: () => {},
    onResetInvoiceInfo: () => {},
    invoicePDF: null,
    invoiceId: null,
    viewOnly: false,
    type: null,
};

export default InvoiceTypeSelection;
