import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

import {
    Box,
    Button,
    CircularProgress,
    FormControlLabel,
    makeStyles,
    Radio,
    RadioGroup,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';

import InvoiceForm from 'components/accountancy/invoice/invoice-form';
import BasicTooltip from 'components/shared/basic-tooltip';
import DisplayPaginatedResults from 'components/shared/display-paginated-results';
import NoDataPlaceholder from 'components/shared/no-data-placeholder';
import GlobalContext from 'contexts/GlobalContext';
import UserContext from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { ExpandableRow, Header, Search, TableSeparator } from 'RaisisComponents/index.js';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { accountancy } from 'routes';
import { errorHandling, formatDate, toLocaleNumber } from 'utils';
import API from 'utils/axios';

const useStyles = makeStyles(() => {
    return {
        error: {
            backgroundColor: `var(--error)`,
            color: `var(--buttons-text)`,
            '&:hover': {
                backgroundColor: `var(--error-light)`,
            },
        },
    };
});

const InvoiceRow = ({ invoice, getInvoices, canAll }) => {
    const styles = useStyles();

    const { t } = useTranslation();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const globalContext = useContext(GlobalContext);
    const { currencyObj, language } = globalContext;

    /**
     * Function to return invoice type with translation
     */
    const invoiceTypeDisplayed = () => {
        let typeDisplay;
        switch (invoice.type) {
            case 'EXPENSE':
                typeDisplay = t('Expense');
                break;
            case 'ADVANCE':
                typeDisplay = t('Advance');
                break;
            case 'REVERSAL':
                typeDisplay = t('Reversal');
                break;
            case 'INCOME':
                if (invoice.incomeType === 'SIMPLE') {
                    typeDisplay = t('Income');
                } else if (invoice.incomeType === 'REVERSE') {
                    typeDisplay = t('Income with advance reversal');
                }
                break;
        }

        return typeDisplay.toUpperCase();
    };

    const changeInvoiceStatus = async () => {
        try {
            await API.patch(`/updateInvoice?id=${invoice.id}`);

            getInvoices();
            enqueueSnackbar(t('The invoice was approved!'), {
                variant: 'success',
            });
        } catch (err) {
            console.error(err);
            enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                variant: 'error',
            });
        }
    };

    const changeInvoicePaymentStatus = async () => {
        try {
            await API.put(`/updateInvoice?id=${invoice.id}`);

            getInvoices();
            enqueueSnackbar(t('The invoice was marked as paid!'), {
                variant: 'success',
            });

            // if (invoice.contractId !== null && invoice.type !== 'ADVANCE') {
            //     history.push(crm.base + crm.contracts.base + crm.contracts.update + '/' + invoice.contractId);
            // }
        } catch (err) {
            console.error(err);
            enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                variant: 'error',
            });
        }
    };

    const statusOfInvoice = () => {
        if (invoice.isValidated) {
            return (
                <div className="flex items-center gap-2">
                    <div className="h-2 w-2 rounded-full bg-success"></div>
                    <div>{t('Validated')}</div>
                </div>
            );
        } else {
            return (
                <div className="flex items-center">
                    <div className="h-2 w-2 rounded-full bg-error"></div>
                    <div className="ml-2">{t('Invalidate')}</div>

                    <div>
                        {canAll && (
                            <BasicTooltip tip={t('Approve the invoice')}>
                                <div
                                    className="ml-3 flex items-center justify-center rounded-full bg-success"
                                    style={{ height: '2rem', width: '2rem' }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        globalContext.setGlobalModalOpen(true);
                                        globalContext.setGlobalModalChildren(
                                            <div className="flex-col">
                                                <p className="mb-8 text-center text-2xl font-bold text-main-text">
                                                    {t('Are you sure you want to')}
                                                    <br />
                                                    {t('approve this invoice?')}
                                                </p>
                                                <div className="flex items-center justify-center">
                                                    <Button
                                                        color="primary"
                                                        onClick={() => {
                                                            changeInvoiceStatus();
                                                            globalContext.setGlobalModalOpen(false);
                                                        }}
                                                    >
                                                        {t('Yes')}
                                                    </Button>
                                                    <div className="w-4" />
                                                    <Button onClick={() => globalContext.setGlobalModalOpen(false)}>
                                                        {t('No')}
                                                    </Button>
                                                </div>
                                            </div>,
                                        );
                                    }}
                                >
                                    <i className="fa-solid fa-square-check text-buttons-text"></i>
                                </div>
                            </BasicTooltip>
                        )}
                    </div>
                </div>
            );
        }
    };

    const statusOfPayment = () => {
        if (invoice.payment === 'PAID') {
            return (
                <div className="flex items-center gap-2">
                    <div className="rounded-full bg-layout-transparent-dark pb-2 pl-4 pr-4 pt-2 font-semibold text-success">
                        {invoice.type === 'EXPENSE' ? t('PAID') : t('COLLECTED')}
                    </div>
                </div>
            );
        } else {
            return (
                <div className="flex items-center">
                    <>
                        <div className="rounded-full bg-layout-transparent-dark pb-2 pl-4 pr-4 pt-2 font-semibold text-warning">
                            {invoice.type === 'EXPENSE' ? t('UNPAID') : t('UNCOLLECTED')}
                        </div>
                        <div>
                            {invoice.isValidated === true && canAll && (
                                <BasicTooltip tip={t('Mark the invoice as collected')}>
                                    <div
                                        className="ml-3 flex items-center justify-center rounded-full bg-success"
                                        style={{ height: '2rem', width: '2rem' }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            globalContext.setGlobalModalOpen(true);
                                            globalContext.setGlobalModalChildren(
                                                <div className="flex-col">
                                                    <p className="mb-8 text-center text-2xl font-bold text-main-text">
                                                        {t('Are you sure you want to')}
                                                        <br />
                                                        {t('mark as collected this invoice?')}
                                                    </p>
                                                    <div className="flex items-center justify-center">
                                                        <Button
                                                            color="primary"
                                                            onClick={() => {
                                                                changeInvoicePaymentStatus();
                                                                globalContext.setGlobalModalOpen(false);
                                                            }}
                                                        >
                                                            {t('Yes')}
                                                        </Button>
                                                        <div className="w-4" />
                                                        <Button onClick={() => globalContext.setGlobalModalOpen(false)}>
                                                            {t('No')}
                                                        </Button>
                                                    </div>
                                                </div>,
                                            );
                                        }}
                                    >
                                        <MonetizationOnIcon className="text-buttons-text" />
                                    </div>
                                </BasicTooltip>
                            )}
                        </div>
                    </>
                </div>
            );
        }
    };

    const newInvoice = {
        ...invoice,
        fileArray: [{ signedUrl: invoice.InvoiceFile[0]?.getSignedUrl }],
    };

    const deleteInvoice = async () => {
        try {
            await API.delete(`/invoice`, {
                params: {
                    id: invoice.id,
                },
            });

            if (invoice.type === 'REVERSAL') {
                await API.put('/invoice', {
                    id: invoice.invoiceId,
                    data: {
                        isUsed: false,
                    },
                });
            } else if (invoice.type === 'ADVANCE' && invoice.isUsed === true) {
                await API.delete(`/invoice`, {
                    params: {
                        id: invoice.Invoice[0].id,
                    },
                });
            } else if (invoice.type === 'INCOME' && invoice.incomeType === 'REVERSE') {
                await API.put('/invoice', {
                    id: invoice.invoiceId,
                    data: {
                        isUsed: false,
                    },
                });
            }

            enqueueSnackbar(t('The invoice was deleted!'), {
                variant: 'success',
            });
            getInvoices();
        } catch (err) {
            console.error(err);
            enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                variant: 'error',
            });
        }
    };

    return (
        <ExpandableRow
            colSpan={7}
            padding={false}
            displayDataOnOpen={true}
            row={
                <>
                    <TableCell>{invoice.name}</TableCell>
                    <TableCell>{invoice.billingNumber}</TableCell>
                    <TableCell>
                        {invoice.type === 'REVERSAL' ? (
                            <div className="font-semibold">
                                -{' '}
                                {toLocaleNumber(
                                    invoice.invoiceIds.reduce((acc, curr) => (acc += curr.value), 0),
                                    language,
                                    2,
                                )}{' '}
                                {currencyObj.currency}
                            </div>
                        ) : (
                            <div className="font-semibold">
                                {toLocaleNumber(invoice.value, language, 2)} {currencyObj.currency}
                            </div>
                        )}
                    </TableCell>

                    <TableCell>
                        <div className="font-semibold italic">{invoiceTypeDisplayed()}</div>
                    </TableCell>
                    <TableCell>{formatDate(invoice.issueDate)}</TableCell>
                    <TableCell>{statusOfInvoice()}</TableCell>
                    <TableCell>{statusOfPayment()}</TableCell>
                    <TableCell>
                        {invoice.isUsed ? (
                            <div className="inline-flex rounded-full bg-layout-transparent-dark pb-2 pl-4 pr-4 pt-2 font-semibold text-primary-light">
                                {t('Reversed')}
                            </div>
                        ) : (
                            ''
                        )}
                    </TableCell>
                </>
            }
            collapse={
                <div className="mx-auto p-5">
                    <div className="mb-10 mt-10 flex gap-5">
                        {invoice.type !== 'ADVANCE' && invoice.isUsed !== true && canAll && (
                            <Button
                                color="primary"
                                startIcon={<i className="fa-solid fa-file-invoice" />}
                                onClick={() =>
                                    history.push(
                                        accountancy.base +
                                            accountancy.invoice.base +
                                            accountancy.invoice.update +
                                            '/' +
                                            invoice.id,
                                    )
                                }
                            >
                                {t('Edit invoice')}
                            </Button>
                        )}

                        {canAll && (
                            <Button
                                className={styles.error}
                                startIcon={<i className="fa-solid fa-trash"></i>}
                                onClick={() => {
                                    globalContext.setGlobalModalOpen(true);
                                    globalContext.setGlobalModalChildren(
                                        <div className="flex-col">
                                            <p className="mb-8 text-center text-2xl font-bold text-main-text">
                                                {t('Are you sure you want to')}
                                                <br />
                                                {t('delete this invoice?')}
                                            </p>
                                            <div className="flex items-center justify-center">
                                                <Button
                                                    color="primary"
                                                    onClick={() => {
                                                        deleteInvoice();
                                                        globalContext.setGlobalModalOpen(false);
                                                    }}
                                                >
                                                    {t('Yes')}
                                                </Button>
                                                <div className="w-4" />
                                                <Button onClick={() => globalContext.setGlobalModalOpen(false)}>
                                                    {t('No')}
                                                </Button>
                                            </div>
                                        </div>,
                                    );
                                }}
                            >
                                {t('Delete invoice')}
                            </Button>
                        )}
                    </div>

                    <InvoiceForm invoiceToEdit={newInvoice} viewOnly={true} />
                </div>
            }
        />
    );
};
InvoiceRow.propTypes = {
    invoice: PropTypes.object,
    getInvoices: PropTypes.func,
    canAll: PropTypes.bool,
};

InvoiceRow.defaultProps = {
    canAll: false,
};

const ManageInvoices = () => {
    const { t } = useTranslation();
    const history = useHistory();

    const { permissionMap } = useContext(UserContext);
    const canView = permissionMap['Facturi'] > 0;
    const canAll = permissionMap['Facturi'] > 1;

    useEffect(() => {
        if (!canView) history.push('/');
    }, [canView]);

    const [query, setQuery] = useState('');
    const isSearch = query.trim().length > 0;

    const [loading, setLoading] = useState(false);

    const [invoiceType, setInvoiceType] = useState('');
    const [invoices, setInvoices] = useState([]);

    const [currentPageForInvoice, setCurrentPageForInvoice] = useState(null);

    const getInvoices = async (currentPage, perPage, controller) => {
        try {
            const response = await API.get('/invoices', {
                params: {
                    type: invoiceType === '' ? undefined : invoiceType,
                    perPage,
                    currentPage,
                    pagesToLoad: 1,
                },
                signal: controller?.signal ?? undefined,
            });

            // We set this state with the current page from the pagination component to be used in table row after a change of invoice status
            setCurrentPageForInvoice(currentPage);

            setInvoices(response.data.invoices);
            return response.data.count;
        } catch (err) {
            throw new Error(err);
        }
    };

    const getSearchedInvoices = async (currentPage, perPage, controller) => {
        try {
            const response = await API.get('/searchInvoice', {
                params: {
                    type: invoiceType === '' ? undefined : invoiceType,
                    text: query,
                    perPage,
                    currentPage,
                    pagesToLoad: 1,
                },
                signal: controller?.signal ?? undefined,
            });

            // We set this state with the current page from the pagination component to be used in table row after a change of invoice status
            setCurrentPageForInvoice(currentPage);

            setInvoices(response.data.invoices);
            return response.data.length;
        } catch (err) {
            throw new Error(err);
        }
    };

    return (
        <>
            <Helmet>
                <title>{t('Invoices')}</title>
            </Helmet>

            <Header
                pageTitle={
                    <div className="flex w-full flex-wrap items-center gap-8 sm:gap-4">
                        <span>{t('Invoices')}</span>

                        <Search searchIconBig={true} value={query} setValue={setQuery} withSearchIcon={false} />
                    </div>
                }
                action={
                    canAll && (
                        <Button
                            color="primary"
                            style={{ borderRadius: '999px' }}
                            startIcon={<AddIcon />}
                            onClick={() =>
                                history.push(accountancy.base + accountancy.invoice.base + accountancy.invoice.create)
                            }
                        >
                            {t('Add invoice')}
                        </Button>
                    )
                }
                toolbar={
                    <div className="flex items-center gap-10 text-main-text">
                        <RadioGroup
                            aria-label="status"
                            name="status-filter"
                            value={invoiceType}
                            onChange={(e) => setInvoiceType(e.target.value)}
                        >
                            <div className="no-user-select-recursive -mr-3 flex items-center text-main-text">
                                <div className="flex flex-wrap">
                                    <FormControlLabel key={'ALL'} value={''} control={<Radio />} label={t('All')} />

                                    <FormControlLabel
                                        key={'EXPENSES'}
                                        value={'EXPENSE'}
                                        control={<Radio />}
                                        label={t('Expense')}
                                    />

                                    <FormControlLabel
                                        key={'INCOME'}
                                        value={'INCOME'}
                                        control={<Radio />}
                                        label={t('Income')}
                                    />

                                    <FormControlLabel
                                        key={'ADVANCE'}
                                        value={'ADVANCE'}
                                        control={<Radio />}
                                        label={t('Advance')}
                                    />

                                    <FormControlLabel
                                        key={'REVERSAL'}
                                        value={'REVERSAL'}
                                        control={<Radio />}
                                        label={t('Reverse')}
                                    />
                                </div>
                            </div>
                        </RadioGroup>
                    </div>
                }
            />

            <div className="page-container">
                <DisplayPaginatedResults
                    loading={loading}
                    query={query}
                    setLoading={setLoading}
                    getFunction={getInvoices}
                    getSearchFunction={getSearchedInvoices}
                    refreshList={[invoiceType]}
                >
                    {loading ? (
                        <div className="flex h-64 w-full items-center justify-center bg-layout-main">
                            <CircularProgress />
                        </div>
                    ) : invoices.length ? (
                        <TableContainer component={Box}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t('Invoice name')}</TableCell>
                                        <TableCell>{t('Invoice number')}</TableCell>
                                        <TableCell>{t('Invoice value')}</TableCell>
                                        <TableCell>{t('Invoice type')}</TableCell>
                                        <TableCell>{t('Issue date')}</TableCell>
                                        <TableCell>{t('Invoice status')}</TableCell>
                                        <TableCell>{t('Payment status')}</TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableSeparator />
                                    {invoices?.map((invoice) => (
                                        <InvoiceRow
                                            key={invoice.id}
                                            invoice={invoice}
                                            getInvoices={() =>
                                                !isSearch
                                                    ? getInvoices(currentPageForInvoice, 10)
                                                    : getSearchedInvoices(currentPageForInvoice, 10)
                                            }
                                            canAll={canAll}
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <NoDataPlaceholder />
                    )}
                </DisplayPaginatedResults>
            </div>
        </>
    );
};

export default ManageInvoices;
