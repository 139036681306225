import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import InvoiceForm from 'components/accountancy/invoice/invoice-form';
import UserContext from 'contexts/UserContext';
import { Header } from 'RaisisComponents/index.js';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';

const CreateInvoice = () => {
    const { t } = useTranslation();
    const { permissionMap } = useContext(UserContext);
    const history = useHistory();
    const canAll = permissionMap['Facturi'] > 1;
    const canAllPM = permissionMap['Modul Project Manager'] > 1;

    useEffect(() => {
        if (!canAll) history.push('/');
    }, [canAll]);

    return (
        <>
            <Helmet>
                <title>{t('Add invoice')}</title>
            </Helmet>

            <Header pageTitle={t('Add invoice')} />

            <div className="page-container">
                <InvoiceForm viewOnly={false} displayPmProjects={canAllPM} />
            </div>
        </>
    );
};

export default CreateInvoice;
