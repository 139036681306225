import React, { useRef } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { displayNumber, formatDate, remToPx } from 'utils';
import { getRowsHeights } from 'utils/financialToolsUtils';

const PADDING_X = '0.25rem';
const PADDING_Y = '0.25rem';
const CELL_WIDTH = '275px';
const BORDER_WIDTH = '1px';

const CashflowTable = ({
    companyLogo,
    headersData,
    excelData,
    exchangeRate,
    exchangeRateDate,
    canRenderNational,
    canRenderReference,
    currencyObj,
    referenceCurrencyObj,
    language,
}) => {
    const { t } = useTranslation();

    const disabledCropHeightsIntervalsRef = useRef([]);

    const headerStyes = { backgroundColor: 'var(--pdf-layout-background)', height: '90px' };

    const headerCellStyles = {
        color: 'var(--pdf-layout-text)',
        padding: `0 ${PADDING_X}`,
        textAlign: 'left',
        fontWeight: 500,
        wordBreak: 'break-word',
        maxWidth: '175px',
        borderBottom: `${BORDER_WIDTH} solid rgb(192,192,192)`,
        borderRight: `${BORDER_WIDTH} solid rgb(192,192,192)`,
    };

    const rowsHeights = getRowsHeights(
        disabledCropHeightsIntervalsRef,
        excelData,
        ['Cont_Contabil', 'Operatiune_Perioada'],
        parseFloat(CELL_WIDTH) - remToPx(PADDING_X) * 2 - parseFloat(BORDER_WIDTH),
        remToPx(PADDING_Y) * 2,
    );

    return (
        <table
            id="cashflow-table"
            className="border-collapse overflow-hidden rounded-md text-xl"
            data-disabledCropHeightsIntervals={JSON.stringify(disabledCropHeightsIntervalsRef.current)}
        >
            <thead>
                <tr style={headerStyes}>
                    <th style={headerCellStyles}>
                        <div className="flex items-center">
                            <img
                                src={companyLogo}
                                placeholder="Company Logo"
                                style={{
                                    minWidth: '5rem',
                                    maxWidth: '5rem',
                                }}
                            />
                        </div>
                    </th>
                    {/* <th style={headerCellStyles}>{t('Criterion Nr.').toUpperCase()}</th> */}
                    <th style={headerCellStyles}>{t('Verification keys').toUpperCase()}</th>
                    <th style={{ ...headerCellStyles, maxWidth: CELL_WIDTH, minWidth: CELL_WIDTH }}>
                        {t('Accounting Account').toUpperCase()}
                    </th>
                    <th style={{ ...headerCellStyles, maxWidth: CELL_WIDTH, minWidth: CELL_WIDTH }}>
                        {t('Operation/Period').toUpperCase()}
                    </th>

                    {headersData.map((row) => (
                        <React.Fragment key={row.name}>
                            {canRenderNational && (
                                <th style={headerCellStyles}>
                                    <div className="flex flex-col gap-1.5">
                                        <span>
                                            {t('Forecasted').toUpperCase()} {row.name}
                                        </span>
                                        <span style={{ minWidth: '6.5rem' }}>
                                            {`${formatDate(row.startDate)}/${formatDate(row.endDate)}`}
                                        </span>
                                        <span>{currencyObj.currency}</span>
                                    </div>
                                </th>
                            )}

                            {canRenderReference && (
                                <th style={headerCellStyles}>
                                    <div className="flex flex-col gap-1.5">
                                        <span>
                                            {t('Forecasted').toUpperCase()} {row.name}
                                        </span>
                                        <span style={{ minWidth: '6.5rem' }}>
                                            {`${formatDate(row.startDate)}/${formatDate(row.endDate)}`}
                                        </span>
                                        <span>{referenceCurrencyObj.currency}</span>
                                    </div>
                                </th>
                            )}

                            {canRenderNational && (
                                <th style={headerCellStyles}>
                                    <div className="flex flex-col gap-1.5">
                                        <span>
                                            {t('Achieved').toUpperCase()} {row.name}
                                        </span>
                                        <span style={{ minWidth: '6.5rem' }}>
                                            {`${formatDate(row.startDate)}/${formatDate(row.endDate)}`}
                                        </span>
                                        <span>{currencyObj.currency}</span>
                                    </div>
                                </th>
                            )}

                            {canRenderReference && (
                                <th style={headerCellStyles}>
                                    <div className="flex flex-col gap-1.5">
                                        <span>
                                            {t('Achieved').toUpperCase()} {row.name}
                                        </span>
                                        <span style={{ minWidth: '6.5rem' }}>
                                            {`${formatDate(row.startDate)}/${formatDate(row.endDate)}`}
                                        </span>
                                        <span>{referenceCurrencyObj.currency}</span>
                                    </div>
                                </th>
                            )}
                        </React.Fragment>
                    ))}
                    {headersData.length > 1 && (
                        <>
                            {canRenderNational && (
                                <th style={headerCellStyles}>
                                    <div className="flex flex-col gap-1.5">
                                        <span>{t(`Total Forecasted`).toUpperCase()}</span>
                                        <span>{currencyObj.currency}</span>
                                    </div>
                                </th>
                            )}
                            {canRenderReference && (
                                <th style={headerCellStyles}>
                                    <div className="flex flex-col gap-1.5">
                                        <span>{t(`Total Forecasted`).toUpperCase()}</span>
                                        <span>{referenceCurrencyObj.currency}</span>
                                    </div>
                                </th>
                            )}

                            {canRenderNational && (
                                <th style={headerCellStyles}>
                                    <div className="flex flex-col gap-1.5">
                                        <span>{t(`Total Achieved`).toUpperCase()}</span>
                                        <span>{currencyObj.currency}</span>
                                    </div>
                                </th>
                            )}
                            {canRenderReference && (
                                <th style={headerCellStyles}>
                                    <div className="flex flex-col gap-1.5">
                                        <span>{t(`Total Achieved`).toUpperCase()}</span>
                                        <span>{referenceCurrencyObj.currency}</span>
                                    </div>
                                </th>
                            )}
                        </>
                    )}
                    <th
                        style={{
                            ...headerCellStyles,
                            borderRight: 'none',
                            borderLeft: `${BORDER_WIDTH} solid rgb(192,192,192)`,
                        }}
                    >
                        {t('Forecasted vs Achieved').toUpperCase()}
                    </th>
                    {canRenderReference && (
                        <th
                            style={{
                                ...headerCellStyles,
                                borderRight: 'none',
                                borderLeft: `${BORDER_WIDTH} solid rgb(192,192,192)`,
                            }}
                        >
                            <div className="flex flex-col gap-1.5">
                                <span>{t('Exchange rate').toUpperCase()}</span>
                                <span>{formatDate(exchangeRateDate)}</span>
                                <span>
                                    {displayNumber(exchangeRate, language, 4, 4)} - {currencyObj.currency}/
                                    {referenceCurrencyObj.currency}
                                </span>
                            </div>
                        </th>
                    )}
                </tr>
            </thead>

            <tbody>
                {Object.keys(excelData).length > 0 &&
                    excelData.excel.map((xlsx, index) => {
                        const keys = headersData.map((header) => header.name);

                        const height = rowsHeights[index];

                        const tableRowStyles = {
                            backgroundColor: xlsx.hex2 ? xlsx.hex2 : '#ffffff',
                            height,
                        };

                        const tableCellStyles = {
                            textAlign: 'center',
                            maxWidth: CELL_WIDTH,
                            ...(index === 0 || index === excelData.excel.length - 1
                                ? { borderRight: `${BORDER_WIDTH} solid rgb(192,192,192)` }
                                : {
                                      borderTop: `${BORDER_WIDTH} solid rgb(192,192,192)`,
                                      borderBottom: `${BORDER_WIDTH} solid rgb(192,192,192)`,
                                      borderRight: `${BORDER_WIDTH} solid rgb(192,192,192)`,
                                  }),
                        };

                        const tableContentStyles = {
                            color: xlsx.textHex ? xlsx.textHex : '#000000',
                            padding: `${PADDING_Y} ${PADDING_X}`,
                            fontWeight: 500,
                            wordBreak: 'break-word',
                        };

                        return (
                            <React.Fragment key={xlsx.name}>
                                <tr style={tableRowStyles}>
                                    <td style={tableCellStyles}>
                                        <p style={tableContentStyles} className="text-xl">
                                            {xlsx.index}
                                        </p>
                                    </td>
                                    <td style={tableCellStyles}>
                                        <p style={tableContentStyles} className="text-xl">
                                            {xlsx.verifyKey}
                                        </p>
                                    </td>
                                    {/* <td style={tableCellStyles}>{xlsx.NrCrt}</td> */}
                                    <td style={{ ...tableCellStyles, textAlign: 'right' }}>
                                        <p style={tableContentStyles} className="text-xl">
                                            {xlsx.Cont_Contabil}
                                        </p>
                                    </td>
                                    <td style={{ ...tableCellStyles, textAlign: 'right' }}>
                                        <p style={tableContentStyles} className="text-xl">
                                            {xlsx.Operatiune_Perioada}
                                        </p>
                                    </td>

                                    {keys.map((keyName) => (
                                        <React.Fragment key={keyName}>
                                            {canRenderNational && (
                                                <td style={tableCellStyles}>
                                                    <p style={tableContentStyles} className="text-xl">
                                                        {displayNumber(
                                                            xlsx[`forecasted_${keyName}`] ?? 0,
                                                            language,
                                                            2,
                                                            2,
                                                        )}
                                                    </p>
                                                </td>
                                            )}
                                            {canRenderReference && (
                                                <td style={tableCellStyles}>
                                                    <p style={tableContentStyles} className="text-xl">
                                                        {displayNumber(
                                                            xlsx[`forecasted_${keyName}_secondValue`] ?? 0,
                                                            language,
                                                            2,
                                                            2,
                                                        )}
                                                    </p>
                                                </td>
                                            )}

                                            {canRenderNational && (
                                                <td style={tableCellStyles}>
                                                    <p style={tableContentStyles} className="text-xl">
                                                        {displayNumber(xlsx[keyName], language)}
                                                    </p>
                                                </td>
                                            )}

                                            {canRenderReference && (
                                                <td style={tableCellStyles}>
                                                    <p style={tableContentStyles} className="text-xl">
                                                        {displayNumber(xlsx[`${keyName}_secondValue`], language)}
                                                    </p>
                                                </td>
                                            )}
                                        </React.Fragment>
                                    ))}
                                    {headersData.length > 1 && (
                                        <>
                                            {canRenderNational && (
                                                <td style={tableCellStyles}>
                                                    <p style={tableContentStyles} className="text-xl">
                                                        {displayNumber(xlsx.total_forecasted ?? 0, language, 2, 2)}
                                                    </p>
                                                </td>
                                            )}
                                            {canRenderReference && (
                                                <td style={tableCellStyles}>
                                                    <p style={tableContentStyles} className="text-xl">
                                                        {displayNumber(
                                                            xlsx.total_forecasted_secondValue ?? 0,
                                                            language,
                                                            2,
                                                            2,
                                                        )}
                                                    </p>
                                                </td>
                                            )}
                                            {canRenderNational && (
                                                <td style={tableCellStyles}>
                                                    <p style={tableContentStyles} className="text-xl">
                                                        {displayNumber(xlsx.total ?? 0, language, 2, 2)}
                                                    </p>
                                                </td>
                                            )}
                                            {canRenderReference && (
                                                <td style={tableCellStyles}>
                                                    <p style={tableContentStyles} className="text-xl">
                                                        {displayNumber(xlsx.total_secondValue ?? 0, language, 2, 2)}
                                                    </p>
                                                </td>
                                            )}
                                        </>
                                    )}
                                    <td style={tableCellStyles}>
                                        <p style={tableContentStyles} className="text-xl">
                                            {`${displayNumber(xlsx['%'], language)} %`}
                                        </p>
                                    </td>
                                    {canRenderReference && <td style={tableCellStyles}>&nbsp;</td>}
                                </tr>
                            </React.Fragment>
                        );
                    })}
            </tbody>
        </table>
    );
};

CashflowTable.propTypes = {
    companyLogo: PropTypes.object,
    headersData: PropTypes.array,
    excelData: PropTypes.object,
    exchangeRate: PropTypes.number,
    exchangeRateDate: PropTypes.instanceOf(Date),
    canRenderNational: PropTypes.bool,
    canRenderReference: PropTypes.bool,
    currencyObj: PropTypes.object,
    referenceCurrencyObj: PropTypes.object,
    language: PropTypes.string,
};

CashflowTable.defaultProps = {
    companyLogo: {},
    headersData: [],
    excelData: {},
    exchangeRate: 0.0001,
    exchangeRateDate: new Date(),
    canRenderNational: true,
    canRenderReference: true,
    currencyObj: {},
    referenceCurrencyObj: {},
    language: 'en',
};

export default CashflowTable;
