import React from 'react';

import PropTypes from 'prop-types';

import ExportTableCell from './export-table-cell';

const ExportTableBody = ({ data, columns }) => {
    return (
        <tbody>
            {data.map((entry, index) => {
                const { textColor, backgroundColor } = entry;

                return (
                    <tr key={index} style={{ backgroundColor }}>
                        {columns.map((column) => {
                            const { key, render } = column;
                            const content = entry[key];

                            return (
                                <ExportTableCell key={key} textColor={textColor} backgroundColor={backgroundColor}>
                                    {render(content, entry, index)}
                                </ExportTableCell>
                            );
                        })}
                    </tr>
                );
            })}
        </tbody>
    );
};

ExportTableBody.propTypes = {
    data: PropTypes.array,
    columns: PropTypes.array,
};

ExportTableBody.defaultProps = {
    data: [],
    columns: [],
};

export default ExportTableBody;
