import React from 'react';

import PropTypes from 'prop-types';

const ExportTableInfo = ({ children, companyLogo, rowLength, textColor, backgroundColor }) => {
    const imageColSpan = Math.max(1, Math.round(rowLength / 3));
    const titleColSpan = Math.max(1, rowLength - imageColSpan);

    return (
        <thead>
            <tr className="border border-black bg-pdf-layout-background">
                <th
                    className="p-3 text-left"
                    colSpan={imageColSpan}
                    data-pdfmake={JSON.stringify({
                        fillColor: backgroundColor,
                    })}
                >
                    <img
                        style={{
                            width: '96px',
                        }}
                        src={companyLogo}
                        placeholder="Logo"
                    />
                </th>
                <th
                    colSpan={titleColSpan}
                    data-pdfmake={JSON.stringify({
                        fillColor: backgroundColor,
                        margin: [0, 5, 0, 0],
                    })}
                >
                    <p
                        className="p-3 leading-10"
                        style={{
                            textAlign: 'right',
                            fontSize: '27px',
                            fontWeight: 'bold',
                            color: textColor,
                        }}
                    >
                        {children}
                    </p>
                </th>
            </tr>
        </thead>
    );
};

ExportTableInfo.propTypes = {
    companyLogo: PropTypes.string,
    children: PropTypes.element,
    rowLength: PropTypes.number,
    textColor: PropTypes.string,
    backgroundColor: PropTypes.string,
};

ExportTableInfo.defaultProps = {
    companyLogo: null,
    children: null,
    rowLength: 0,
    textColor: null,
    backgroundColor: null,
};

export default ExportTableInfo;
