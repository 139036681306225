import React, { useContext, useEffect, useState } from 'react';

import { FileUp, ListFilter } from 'lucide-react';

import { Button, CircularProgress } from '@material-ui/core';

import ExportTable from 'components/shared/export/export-table';
import Loading from 'components/shared/loading';
import NoDataPlaceholder from 'components/shared/no-data-placeholder';
import GlobalContext from 'contexts/GlobalContext';
import { Header } from 'RaisisComponents';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { displayNumber, getRawImageFromPath } from 'utils';
import API from 'utils/axios';
import exportHtmlAsPDF from 'utils/exportHtmlAsPDF';
import { getCompanyLogoAndIcon } from 'utils/getterFunctions';

import ModalReportsExport from './modal-report-filter';

const Report = () => {
    const { t } = useTranslation();
    const {
        tenant: { companyName },
        currencyObj,
        referenceCurrencyObj,
        language,
    } = useContext(GlobalContext);

    const columns = [
        { key: 'number', content: null, render: (content, entry, index) => index + 1 },
        { key: 'name', content: t('Product name'), render: (content, entry, index) => content },
        { key: 'categories', content: t('Categories'), render: (content, entry, index) => content },
        { key: 'description', content: t('Description'), render: (content, entry, index) => content },
        { key: 'project', content: t('Project name'), render: (content, entry, index) => content },
        {
            key: 'mainPrice',
            content: `${t('Price')} (${currencyObj.currency})`,
            render: (content, entry, index) => displayNumber(content, language),
        },
        {
            key: 'secondaryPrice',
            content: `${t('Price')} (${referenceCurrencyObj.currency})`,
            render: (content, entry, index) => displayNumber(content, language),
        },
        { key: 'totalQuantity', content: t('Stock quantity'), render: (content, entry, index) => content },
        { key: 'freeQuantity', content: t('Free quantity'), render: (content, entry, index) => content },
        { key: 'reservedQuantity', content: t('Bidded quantity'), render: (content, entry, index) => content },
        { key: 'soldQuantity', content: t('Sold quantity'), render: (content, entry, index) => content },
        { key: 'leads', content: t('Leads'), render: (content, entry, index) => content },
    ];

    const info = {
        description: t('Explanations of columns'),
        elements: [
            { label: t('Name'), content: t('Name of the article or stock.') },
            { label: t('Price'), content: t('Price of the article or stock.') },
            { label: t('Categories'), content: t('Categories of the article or stock.') },
            { label: t('Description'), content: t('Description of the article or stock.') },
            { label: t('Project name'), content: t('Project of the article or stock.') },
            { label: t('Quantity'), content: t('Number of units in stock.') },
            { label: t('Free quantity'), content: t('Number of units available for sale.') },
            {
                label: t('Bidded quantity'),
                content: t('Number of units reserved for customers (not yet invoiced).'),
            },
            { label: t('Leads'), content: t('The leeds to whom the article is bidded.') },
        ],
    };

    const [loading, setLoading] = useState(true);
    const [exporting, setExporting] = useState(false);
    const [fetching, setFetching] = useState(false);

    const [stocks, setStocks] = useState([]);
    const [projects, setProjects] = useState([]);
    const [companyLogo, setCompanyLogo] = useState(null);

    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);

    const [filteredOptions, setFilteredOptions] = useState({
        detailsType: 'SYNTHETIC',
        type: 'COMPANY',
        options: [],
    });

    useEffect(() => {
        (async () => {
            try {
                const [dataRes, stocksRes, projectsRes, { logoUrl }] = await Promise.all([
                    API.get('/article_reports', { params: filteredOptions }),
                    API.get('/stocks', {
                        params: {
                            perPage: 99999,
                            currentPage: 0,
                            pagesToLoad: 1,
                        },
                    }),
                    API.get('/PmProjects', {
                        params: {
                            perPage: 99999,
                            currentPage: 0,
                            pagesToLoad: 1,
                        },
                    }),
                    getCompanyLogoAndIcon(),
                ]);

                const logoBase64 = await getRawImageFromPath(logoUrl);
                setCompanyLogo(logoBase64);

                setData(dataRes.data.report);
                setStocks(stocksRes.data.stocks.content);
                setProjects(projectsRes.data.PmProjects.content);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleFilter = async (params) => {
        try {
            const dataRes = await API.get('/article_reports', { params });

            setFetching(true);
            setData(dataRes.data.report);
        } catch (error) {
            console.error(error);
        } finally {
            setFetching(false);
        }
    };

    const handleExport = async () => {
        try {
            setExporting(true);

            const html = document.getElementById('stocks-report-table').innerHTML;

            const columnsWidths = ['4%', '10%', '10%', '16%', '10%', '10%', '10%', '5%', '5%', '5%', '5%', '10%'];

            await exportHtmlAsPDF(
                'landscape',
                [html],
                [],
                null,
                null,
                true,
                null,
                `Articles_Report_Export_${new Date().toISOString().slice(0, 5)}_${companyName}.pdf`,
                [
                    {
                        elements: [
                            {
                                key: 'table',
                                props: {
                                    widths: columnsWidths,
                                    dontBreakRows: true,
                                },
                            },
                        ],
                    },
                ],
                [],
            );
        } catch (error) {
            console.error(error);
        } finally {
            setExporting(false);
        }
    };

    return (
        <>
            <Helmet>
                <title>{t('Stocks report')}</title>
            </Helmet>
            <Header
                pageTitle={t('Stocks report')}
                action={
                    <>
                        <Button
                            color="primary"
                            startIcon={fetching ? <CircularProgress size={20} /> : <ListFilter />}
                            style={{ borderRadius: '999px' }}
                            onClick={handleOpen}
                            disabled={loading || fetching || exporting}
                        >
                            {t(fetching ? 'Loading...' : 'Filter data')}
                        </Button>
                        <Button
                            color="secondary"
                            startIcon={exporting ? <CircularProgress size={20} /> : <FileUp />}
                            style={{ borderRadius: '999px' }}
                            onClick={handleExport}
                            disabled={loading || fetching || exporting || !data.length}
                        >
                            {t(exporting ? 'Loading...' : 'Export as PDF')}
                        </Button>
                    </>
                }
            />

            <div className="page-container">
                {loading ? (
                    <Loading style={{ height: '100%', margin: 'auto 0' }} />
                ) : data.length ? (
                    <div className="overflow-x-auto" id="stocks-report-table">
                        <ExportTable
                            companyLogo={companyLogo}
                            title={t('Stocks report')}
                            columns={columns}
                            data={data}
                            info={info}
                        />
                    </div>
                ) : (
                    <NoDataPlaceholder />
                )}
            </div>

            <ModalReportsExport
                open={open}
                onClose={handleClose}
                onFilter={handleFilter}
                stocks={stocks}
                projects={projects}
                filteredOptions={filteredOptions}
                setFilteredOptions={setFilteredOptions}
            />
        </>
    );
};

export default Report;
