import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import { Button } from '@material-ui/core';

import ArticleRow from 'components/crm/quotes/create-quotes/article-row';
import AttributesAndVariationsRow from 'components/crm/quotes/create-quotes/attributes-variations-row';
import Loading from 'components/shared/loading';
import GlobalContext from 'contexts/GlobalContext';
import OfferContext from 'contexts/OfferContext';
import UserContext from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { Dropdown, LabelWrapper } from 'RaisisComponents/index.js';
import { useTranslation } from 'react-i18next';
import { quotes } from 'routes';
import API from 'utils/axios';
import { getStocksArticles } from 'utils/getterFunctions';
import { calculateVATValue, errorHandling, toLocaleNumber } from 'utils/index';
import * as yup from 'yup';

const OfferArticles = ({ viewOnly }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const { currencyObj, language } = useContext(GlobalContext);

    const { checkPerm } = useContext(UserContext);

    const canAllArticles = checkPerm([
        {
            permissionId: '18',
            permissionType: 'ALL',
        },
    ]);

    const [loading, setLoading] = useState(true);

    const optionClass = 'text-lg text-main-text font-bold flex items-center';

    const { offerContext, editOfferContext } = useContext(OfferContext);

    const [articlesFromNomenclature, setArticlesFromNomenclature] = useState([]);
    const [articlesFromStocks, setArticlesFromStocks] = useState([]);
    const [selectedArticles, setSelectedArticles] = useState([]);

    const [unitList, setUnitList] = useState([
        t('Hours'),
        t('Days'),
        t('Piece'),
        'Kg',
        t('Linear meter'),
        t('Square meter'),
        t('Add new UM'),
    ]);

    const handleSetInitialSelectedArticles = (offer) => {
        const newSelectedArticlesForm = offer.offerArticles.map((a) => ({
            ...a,
            selectedAttributes: [],
            selectedVariations: [],
        }));
        setSelectedArticles(newSelectedArticlesForm);
    };

    /*
        Fetch Articles
    */
    useEffect(() => {
        (async () => {
            try {
                const [articlesRes, articlesFromStocks] = await Promise.all([
                    await API.get('/articles', {
                        params: {
                            perPage: 99999,
                            currentPage: 0,
                            pagesToLoad: 1,
                            type: null,
                            categorieId: null,
                            many: 'ALL',
                        },
                    }),
                    getStocksArticles(),
                ]);

                setArticlesFromNomenclature(articlesRes.data.articles);
                setArticlesFromStocks(articlesFromStocks);

                // we add in articles an empty selectedAttributes and selectedVariations for attributes and variants dropdowns
                if (offerContext.offer && offerContext.offer.offerArticles.length)
                    handleSetInitialSelectedArticles(offerContext.offer);
            } catch (error) {
                console.error(error);
                enqueueSnackbar(errorHandling(error), { variant: 'error' });
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    /**
     * Edit article functions called in ArticleRow to edit variables
     * @param {*} id - of the article
     * @param {*} key - key of the object which is wanted to be edited
     * @param {*} value - to be changed
     */
    const editArticle = (id, key, value) => {
        const articleIndex = selectedArticles.findIndex((a) => a.id == id);
        const newArticles = [...selectedArticles];
        newArticles[articleIndex][key] = value;
        setSelectedArticles(newArticles);
    };

    const deleteArticle = (id) => {
        setSelectedArticles(selectedArticles.filter((a) => a.id != id));
    };

    /*
        Calculate totals
    */
    const totalPrice = useMemo(
        () =>
            selectedArticles?.map(
                (a) =>
                    Number(a?.price?.pricePerUnit * a.amount) +
                    Number(
                        a.selectedVariables
                            ?.map((v) => Number(v.price?.pricePerUnit * (v.amount || v.quantity))) // variables on the article don't have amount every time and we choose between amount and quantity
                            ?.reduce((a, b) => a + b, 0),
                    ) +
                    Number(
                        a.selectedAttributes
                            ?.map((a) => a.price?.pricePerUnit)
                            ?.reduce((a, b) => a + b, 0)
                            .toFixed(2),
                    ) +
                    Number(
                        a.selectedVariations
                            ?.map((a) => a.price?.pricePerUnit)
                            ?.reduce((a, b) => a + b, 0)
                            .toFixed(2),
                    ),
            ),
        [selectedArticles],
    );

    const totalVatValue = useMemo(() =>
        selectedArticles?.map(
            (a) =>
                Number(calculateVATValue(a?.price?.pricePerUnit, a?.price?.procentualVAT) * a.amount) +
                a.selectedVariables
                    ?.map(
                        (v) =>
                            Number(
                                calculateVATValue(v.price.pricePerUnit, v.price.procentualVAT) *
                                    (v.amount || v.quantity),
                            ), // variables on the article don't have amount every time and we choose between amount and quantity
                    )
                    ?.reduce((a, b) => a + b, 0) +
                a.selectedAttributes
                    ?.map((at) => Number(calculateVATValue(at.price.pricePerUnit, at.price.procentualVAT) * 1))
                    ?.reduce((a, b) => a + b, 0) +
                a.selectedVariations
                    ?.map((vr) => Number(calculateVATValue(vr.price.pricePerUnit, vr.price.procentualVAT) * 1))
                    ?.reduce((a, b) => a + b, 0),
            [selectedArticles],
        ),
    );

    const schema = yup.object().shape({
        selectedArticles: yup
            .array()
            .of(
                yup.object().shape({
                    amount: yup
                        .number()
                        .typeError(t('The article amount must be at least 1 unit!'))
                        .min(1, t('The article amount must be at least 1 unit!'))
                        .required(t('The article amount must be at least 1 unit!')),
                    selectedVariables: yup.array().of(
                        yup.object().shape({
                            amount: yup
                                .number()
                                .typeError(t('The variable amount must be at least 1 unit!'))
                                .min(1, t('The variable amount must be at least 1 unit!')),
                            // .required(t('The variable amount must be at least 1 unit!')),
                        }),
                    ),
                }),
            )
            .typeError(t('Selecting at least one article for offer is required!')),
    });

    const addArticlesToTheOfferOrUpdate = async () => {
        try {
            try {
                setLoading(true);

                await schema.validate({
                    selectedArticles,
                });

                const res = await API.post(`/offer_completion/${offerContext.offerId}/ARTICLE`, {
                    articles: selectedArticles?.map((a) => ({
                        id: a.id,
                        quantity: Number(a.amount ? a.amount : 1),
                        options: a.selectedVariables
                            ?.filter((b) => !b.custom)
                            .map((v) => ({
                                id: isNaN(v.id) ? v.id : v.id.toString(),
                                quantity: Number(v.amount || v.quantity), // variables on the article don't have amount every time and we choose between amount and quantity
                            })),
                        attributes: a.selectedAttributes?.map((attribute) => ({
                            name: attribute.name,
                            unit: attribute.price.unit,
                            pricePerUnit: attribute.price.pricePerUnit,
                            procentualVAT: attribute.price.procentualVAT == null ? 0 : attribute.price.procentualVAT,
                            originalArticleAttributeId: attribute.id,
                        })),
                        variations: a.selectedVariations?.map((variation) => ({
                            name: variation.name,
                            unit: variation.price.unit,
                            pricePerUnit: variation.price.pricePerUnit,
                            procentualVAT: variation.price.procentualVAT == null ? 0 : variation.price.procentualVAT,
                            originalArticleVariationsId: variation.id,
                        })),
                        customOptions: a.selectedVariables
                            ?.filter((b) => b.custom)
                            .map((b) => {
                                return {
                                    quantity: b.amount || b.quantity, // the custom variable on the article don't have amount every time and we choose between amount and quantity
                                    name: b.name,
                                    sku: '123',
                                    description: b.description,
                                    price: {
                                        type: b.price.type,
                                        unit: unitList[b.price.unit]
                                            ? unitList[b.price.unit]
                                            : unitList[unitList.findIndex((u) => u === b.price.unit)],
                                        pricePerUnit: Number(b.price.pricePerUnit),
                                        procentualVAT: b.price.procentualVAT,
                                    },
                                };
                            }),
                    })),
                });

                enqueueSnackbar(t('The second step of the offer is successfully accomplished!'), {
                    variant: 'success',
                });

                editOfferContext('offer', res.data.offer);
                editOfferContext('step', 2);
            } catch (err) {
                console.error(err);
                enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                    variant: 'error',
                });
            }
        } catch (err) {
            enqueueSnackbar(err.errors[0], { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const hiddenOptionsFromNomenclature = useMemo(() => {
        return selectedArticles.reduce((acc, curr) => {
            const idx = articlesFromNomenclature.findIndex((art) => art.id === curr.id);
            return idx >= 0 ? [...acc, idx] : acc;
        }, []);
    }, [articlesFromNomenclature, selectedArticles]);

    const hiddenOptionsFromStocks = useMemo(() => {
        return selectedArticles.reduce((acc, curr) => {
            const idx = articlesFromStocks.findIndex((art) => art.id === curr.id);
            return idx >= 0 ? [...acc, idx] : acc;
        }, []);
    }, [articlesFromStocks, selectedArticles]);

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <div className="grid w-3/4 grid-cols-offer-articles gap-16 2xl:w-full xl:gap-8 lg:grid-cols-1 lg:gap-4">
                    <div className="w-full">
                        {selectedArticles.map((a) => (
                            <>
                                <ArticleRow
                                    article={a}
                                    key={a.id}
                                    editArticle={editArticle}
                                    unitList={unitList}
                                    setUnitList={setUnitList}
                                    deleteArticle={() => deleteArticle(a.id)}
                                    disabled={viewOnly}
                                />

                                {/* If article does not have attributes and variations, we do not display the component */}
                                {(a.ArticleAttribute.length > 0 || a.ArticleVariations.length > 0) && (
                                    <AttributesAndVariationsRow
                                        article={a}
                                        editArticle={editArticle}
                                        disabled={viewOnly}
                                    />
                                )}
                            </>
                        ))}

                        {/* Articles dropdown */}
                        {(articlesFromNomenclature.length > 0 || articlesFromStocks.length > 0) && !viewOnly && (
                            <div className="mb-10 inline-flex gap-4">
                                <div>
                                    <Dropdown
                                        options={articlesFromNomenclature.map((a) => a.name)}
                                        hiddenOptions={hiddenOptionsFromNomenclature}
                                        selectedOption={null}
                                        setSelectedOption={(idx) => {
                                            const articleToAdd = articlesFromNomenclature[idx];
                                            articleToAdd.selectedVariables = [];
                                            articleToAdd.selectedAttributes = [];
                                            articleToAdd.selectedVariations = [];
                                            setSelectedArticles([...selectedArticles, articleToAdd]);
                                        }}
                                        placeholder={t('Add article from nomenclature')}
                                    />
                                </div>
                                <div>
                                    <Dropdown
                                        options={articlesFromStocks.map((a) => `${a.name} - (${a.Stock.name})`)}
                                        hiddenOptions={hiddenOptionsFromStocks}
                                        selectedOption={null}
                                        setSelectedOption={(idx) => {
                                            const articleToAdd = articlesFromStocks[idx];
                                            articleToAdd.selectedVariables = [];
                                            articleToAdd.selectedAttributes = [];
                                            articleToAdd.selectedVariations = [];
                                            setSelectedArticles([...selectedArticles, articleToAdd]);
                                        }}
                                        placeholder={t('Add article from stocks')}
                                        withDescription={{
                                            ok: {
                                                message: () => t('You can select this article!'),
                                            },
                                            warning: {
                                                message: () =>
                                                    t('You can select this article, but it is on another open offer!'),
                                                check: (artIdx) =>
                                                    !!articlesFromStocks[artIdx].OfferArticles.find(
                                                        (offer) => offer.articleStatus === 'PRIMARY',
                                                    ),
                                            },
                                        }}
                                    />
                                </div>
                            </div>
                        )}

                        {articlesFromNomenclature.length === 0 && articlesFromStocks.length === 0 && !viewOnly && (
                            <div className="mb-5 flex flex-col items-center justify-center gap-5 rounded-md bg-layout-transparent px-4 py-8 shadow">
                                <p>
                                    {selectedArticles.length
                                        ? t(
                                              'The are no articles left to be selected. If you wish to add more articles, must add them in the nomenclature',
                                          )
                                        : t('There are no articles yet, you have to add one first')}
                                </p>
                                {canAllArticles && (
                                    <Button
                                        color="primary"
                                        onClick={() =>
                                            history.push(
                                                quotes.base + quotes.nomenclature.base + quotes.nomenclature.create,
                                            )
                                        }
                                    >
                                        {t('Add article')}
                                    </Button>
                                )}
                            </div>
                        )}

                        {selectedArticles.length === 0 && viewOnly && (
                            <div className="flex w-full items-center justify-center rounded-md bg-layout-transparent px-4 py-8 shadow">
                                <p>{t('There are no articles on this offer!')}</p>
                            </div>
                        )}
                    </div>

                    {/* Summary info table */}
                    {selectedArticles.length > 0 ? (
                        <div className="w-full">
                            <div className="sticky top-16">
                                <h3 className="rounded-tl-md rounded-tr-md border-b border-layout-transparent bg-layout-transparent-dark p-7 text-2xl font-bold">
                                    {t('Summary')}
                                </h3>

                                {/* Article list */}
                                {selectedArticles.map((a) => (
                                    <div className="bg-layout-transparent p-7" key={a.id}>
                                        <h4 className="mb-4 text-xl">{a.name}</h4>

                                        <div className="grid grid-cols-3 gap-4 border-b border-layout-transparent pb-4 sm:grid-cols-1">
                                            <LabelWrapper label={t('Price without VAT')} noPadding>
                                                <p className={optionClass}>
                                                    {toLocaleNumber(
                                                        Number(a.price.pricePerUnit * a.amount) +
                                                            Number(
                                                                a.selectedVariables
                                                                    ?.map((v) =>
                                                                        Number(
                                                                            v.price.pricePerUnit *
                                                                                (v.amount || v.quantity),
                                                                        ),
                                                                    )
                                                                    ?.reduce((a, b) => a + b, 0)
                                                                    .toFixed(2),
                                                            ) +
                                                            Number(
                                                                a.selectedAttributes
                                                                    ?.map((a) => a.price.pricePerUnit)
                                                                    ?.reduce((a, b) => a + b, 0)
                                                                    .toFixed(2),
                                                            ) +
                                                            Number(
                                                                a.selectedVariations
                                                                    ?.map((a) => a.price.pricePerUnit)
                                                                    ?.reduce((a, b) => a + b, 0)
                                                                    .toFixed(2),
                                                            ),
                                                        language,
                                                        2,
                                                    )}{' '}
                                                    {currencyObj.currency}
                                                </p>
                                            </LabelWrapper>

                                            <LabelWrapper label={t('VAT value')} noPadding>
                                                <p className={optionClass}>
                                                    {toLocaleNumber(
                                                        Number(
                                                            calculateVATValue(
                                                                a.price.pricePerUnit,
                                                                a.price.procentualVAT,
                                                            ) * a.amount,
                                                        ) +
                                                            a.selectedVariables
                                                                ?.map((v) =>
                                                                    Number(
                                                                        calculateVATValue(
                                                                            v.price.pricePerUnit,
                                                                            v.price.procentualVAT,
                                                                        ) * (v.amount || v.quantity),
                                                                    ),
                                                                )
                                                                ?.reduce((a, b) => a + b, 0) +
                                                            a.selectedAttributes
                                                                ?.map((at) =>
                                                                    Number(
                                                                        calculateVATValue(
                                                                            at.price.pricePerUnit,
                                                                            at.price.procentualVAT,
                                                                        ) * 1,
                                                                    ),
                                                                )
                                                                ?.reduce((a, b) => a + b, 0) +
                                                            a.selectedVariations
                                                                ?.map((vr) =>
                                                                    Number(
                                                                        calculateVATValue(
                                                                            vr.price.pricePerUnit,
                                                                            vr.price.procentualVAT,
                                                                        ) * 1,
                                                                    ),
                                                                )
                                                                ?.reduce((a, b) => a + b, 0),
                                                        language,
                                                        2,
                                                    )}{' '}
                                                    {currencyObj.currency}
                                                </p>
                                            </LabelWrapper>

                                            <LabelWrapper label={t('Final price with VAT')} noPadding>
                                                <p className={optionClass}>
                                                    {toLocaleNumber(
                                                        Number(a.price.pricePerUnit * a.amount) +
                                                            Number(
                                                                a.selectedVariables
                                                                    ?.map((v) =>
                                                                        Number(
                                                                            v.price.pricePerUnit *
                                                                                (v.amount || v.quantity),
                                                                        ),
                                                                    )
                                                                    ?.reduce((a, b) => a + b, 0),
                                                            ) +
                                                            Number(
                                                                calculateVATValue(
                                                                    a.price.pricePerUnit,
                                                                    a.price.procentualVAT,
                                                                ) * a.amount,
                                                            ) +
                                                            a.selectedVariables
                                                                ?.map((v) =>
                                                                    Number(
                                                                        calculateVATValue(
                                                                            v.price.pricePerUnit,
                                                                            v.price.procentualVAT,
                                                                        ) * (v.amount || v.quantity),
                                                                    ),
                                                                )
                                                                ?.reduce((a, b) => a + b, 0) +
                                                            Number(
                                                                a.selectedAttributes
                                                                    ?.map((a) => a.price.pricePerUnit)
                                                                    ?.reduce((a, b) => a + b, 0)
                                                                    .toFixed(2),
                                                            ) +
                                                            Number(
                                                                a.selectedVariations
                                                                    ?.map((a) => a.price.pricePerUnit)
                                                                    ?.reduce((a, b) => a + b, 0)
                                                                    .toFixed(2),
                                                            ) +
                                                            a.selectedAttributes
                                                                ?.map((at) =>
                                                                    Number(
                                                                        calculateVATValue(
                                                                            at.price.pricePerUnit,
                                                                            at.price.procentualVAT,
                                                                        ) * 1,
                                                                    ),
                                                                )
                                                                ?.reduce((a, b) => a + b, 0) +
                                                            a.selectedVariations
                                                                ?.map((vr) =>
                                                                    Number(
                                                                        calculateVATValue(
                                                                            vr.price.pricePerUnit,
                                                                            vr.price.procentualVAT,
                                                                        ) * 1,
                                                                    ),
                                                                )
                                                                ?.reduce((a, b) => a + b, 0),
                                                        language,
                                                        2,
                                                    )}{' '}
                                                    {currencyObj.currency}
                                                </p>
                                            </LabelWrapper>
                                        </div>
                                    </div>
                                ))}

                                {/* Final price */}
                                <div className="rounded-bl-md rounded-br-md bg-layout-transparent-dark p-7">
                                    <h4 className="mb-4 text-xl">{t('Final price')}</h4>
                                    <div className="grid grid-cols-3 gap-2 sm:grid-cols-1">
                                        <div>
                                            <LabelWrapper label={t('Price without VAT')} noPadding>
                                                <div className="pointer-events-none rounded-md bg-layout-transparent p-3 text-main-text">
                                                    {toLocaleNumber(
                                                        totalPrice.reduce((a, b) => a + b, 0),
                                                        language,
                                                        2,
                                                    )}{' '}
                                                    {currencyObj.currency}
                                                </div>
                                            </LabelWrapper>
                                        </div>

                                        <div>
                                            <LabelWrapper label={t('VAT value')} noPadding>
                                                <div className="pointer-events-none rounded-md bg-layout-transparent p-3 text-main-text">
                                                    {toLocaleNumber(
                                                        totalVatValue.reduce((a, b) => a + b, 0),
                                                        language,
                                                        2,
                                                    )}{' '}
                                                    {currencyObj.currency}
                                                </div>
                                            </LabelWrapper>
                                        </div>

                                        <div>
                                            <LabelWrapper label={t('Final price with VAT')} noPadding>
                                                <div className="pointer-events-none rounded-md bg-layout-transparent p-3 text-main-text">
                                                    {toLocaleNumber(
                                                        totalPrice.reduce((a, b) => a + b, 0) +
                                                            totalVatValue.reduce((a, b) => a + b, 0),
                                                        language,
                                                        2,
                                                    )}{' '}
                                                    {currencyObj.currency}
                                                </div>
                                            </LabelWrapper>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <Fragment>&nbsp;</Fragment>
                    )}

                    {!viewOnly && (
                        <div className="flex gap-6">
                            <Button
                                color="primary"
                                startIcon={<ArrowBackIosIcon />}
                                onClick={() => editOfferContext('step', 0)}
                            >
                                {t('Step')} 1
                            </Button>

                            <Button
                                color="primary"
                                endIcon={<ArrowForwardIosIcon />}
                                onClick={addArticlesToTheOfferOrUpdate}
                            >
                                {t('Step')} 3
                            </Button>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

OfferArticles.propTypes = {
    viewOnly: PropTypes.bool,
};

OfferArticles.defaultProps = { viewOnly: false };

export default OfferArticles;
